import React from 'react'
import { AppHeader } from './app-header'
import { Header } from './components/header'
import { SmartInstallmentsList } from './components/smart-installments-list'
import { SmartInstallmentsSummarie } from './components/smart-installments-summarie'

export const UserSmartInstallmentsPage: React.FC = () => {
  return (
    <div className="flex flex-col container px-4 md:px-24">
      <AppHeader />
      <div className="flex flex-col pt-0 md:pt-12 pb-12 gap-8">
        <Header />
        <SmartInstallmentsSummarie />
        <SmartInstallmentsList />
      </div>
    </div>
  )
}
