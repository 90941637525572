import { LinkBreak2Icon } from '@radix-ui/react-icons'
import { AlertCircleIcon, CircleCheck, Loader2 } from 'lucide-react'
import { useParams } from 'react-router-dom'
import { AuthLayout } from '../../components/auth-layout'
import { useAuthenticateAuthLink } from '../../hooks/use-authenticate-auth-link'

const SuccessMessage = ({ data }) => {
  return (
    <div className="flex flex-col items-center">
      <CircleCheck className="fill-lime-600 h-14 w-14 mb-2" />
      <h1 className="text-primary-foreground text-center text-xl mb-1 font-bold">Redirecionando...</h1>
      {data?.redirectUrl && (
        <p className="text-primary-foreground/75 text-base">
          Caso não seja redirecionado,{' '}
          <a className="text-lime-600 underline" href={data?.redirectUrl}>
            clique aqui
          </a>
          .
        </p>
      )}
    </div>
  )
}

const UnknownError = ({ error }) => {
  return (
    <div className="flex flex-col items-center">
      <AlertCircleIcon className="h-14 w-14 mb-2 fill-red-500" />
      <h1 className="text-center text-xl mb-1 text-red-500 font-bold">Erro inesperado</h1>
      {error?.message && (
        <p className="text-red-500 text-base">
          {error?.message === 'Auth link reached usage limit of 10'
            ? 'Esse link de autenticação foi utilizado 10 vezes. Será necessário gerar um novo link ou fazer login.'
            : error?.message}
        </p>
      )}
      <p className="text-red-500">Redirecionando para o login em 10 segundos...</p>
    </div>
  )
}

const UsageLimitError = ({ error }) => {
  return (
    <div className="flex flex-col items-center">
      <LinkBreak2Icon className="h-14 w-14 mb-2 text-yellow-500" />
      <h1 className="text-center text-xl mb-1 text-yellow-500 font-bold">Utilização do link esgotada</h1>
      {error?.message && (
        <p className="text-yellow-500 text-base">
          Esse link de autenticação automática foi utilizado 10 vezes. Será necessário fazer login.
        </p>
      )}
      <p className="text-yellow-500">Redirecionando para o login em 10 segundos...</p>
    </div>
  )
}

const AutoAuthPage = () => {
  const { id } = useParams<{ id: string }>()

  const { data, isLoading, error, isSuccess } = useAuthenticateAuthLink(id, {
    onSuccess: async (data) => {
      window.location.replace(data?.redirectUrl ?? '/')
    },
    onError: async (error) => {
      await new Promise((resolve) => setTimeout(resolve, 10000))
      /* If the error has a redirectUrl, we should use it to redirect the user to login with redirectUrl to redirect after login */
      const fallbackRedirectUrl = error?.details?.redirectUrl
      if (fallbackRedirectUrl) {
        const redirectTo = (fallbackRedirectUrl ?? '')?.startsWith('http')
          ? fallbackRedirectUrl
          : `${window.location.protocol}//${window.location.host}${fallbackRedirectUrl}`

        window.location.replace(`/signin?redirectTo=${redirectTo}`)
        return
      }

      window.location.replace(`/signin`)
    },
  })

  const isUsageLimitError = error?.message === 'Auth link reached usage limit of 10'

  return (
    <AuthLayout>
      <div className="flex items-center justify-center">
        {isLoading && <Loader2 className="text-primary-foreground animate-spin h-14 w-14" />}
        {isUsageLimitError && <UsageLimitError error={error} />}
        {error && !isUsageLimitError && <UnknownError error={error} />}
        {isSuccess && <SuccessMessage data={data} />}
      </div>
    </AuthLayout>
  )
}

export { AutoAuthPage }
