import { Badge, Button, Input, Label, Popover, PopoverContent, PopoverTrigger, Separator } from '@hub-la/shadcn'
import { PlusCircle } from 'lucide-react'
import React, { useCallback, useEffect, useState } from 'react'

export const SelectUtm = ({ filters, setFilters }) => {
  const [tempFilters, setTempFilters] = useState(filters)
  const [isOpen, setIsOpen] = useState(false)

  // Make sure tempFilters is always synced with filters
  useEffect(() => {
    setTempFilters(filters)
  }, [filters])

  const hasUtmParams = Object.keys(filters).some(
    (key) => key.startsWith('utm') && filters[key] !== '' && filters[key] !== undefined && filters[key] !== null,
  )

  const applyFilters = () => {
    setFilters(tempFilters)
    setIsOpen(false)
  }

  const clearFilters = () => {
    const defaultFilters = {
      ...filters,
      utmSource: undefined,
      utmMedium: undefined,
      utmCampaign: undefined,
      utmContent: undefined,
      utmTerm: undefined,
    }
    setFilters(defaultFilters)
    setTempFilters(defaultFilters)
  }

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      // Reset tempFilters to current filters when closing
      setTempFilters(filters)
    }
    setIsOpen(open)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target
    setTempFilters(() => ({ ...tempFilters, [id]: value }))
  }

  const hasChanges = useCallback(() => {
    return Object.keys(filters).some((key) => filters[key] !== tempFilters[key])
  }, [filters, tempFilters])

  const getActiveUtmParams = () => {
    return Object.entries(filters)
      .filter(([key, value]) => key.startsWith('utm') && value !== '' && value !== undefined && value !== null)
      .map(([key, value]) => ({ key: key.replace('utm', ''), value }))
  }

  return (
    <Popover open={isOpen} onOpenChange={handleOpenChange}>
      <PopoverTrigger asChild>
        <Button variant="outline">
          <PlusCircle className="h-4 w-4 mr-1" />
          <span className="text-sm">UTM's</span>
          {hasUtmParams && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <div className="flex gap-1">
                {(() => {
                  const activeParams = getActiveUtmParams()
                  return activeParams.length < 3 ? (
                    activeParams.map(({ key, value }) => (
                      <Badge key={key} variant="secondary" className="font-normal px-2 text-xs">
                        <>{value}</>
                      </Badge>
                    ))
                  ) : (
                    <Badge variant="secondary" className="font-normal px-2 text-xs">
                      {activeParams.length} selecionados
                    </Badge>
                  )
                })()}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto z-10002" align="center">
        <div className="grid gap-4">
          <div className="space-y-2">
            <h4 className="font-medium leading-none">Parâmetros de UTM</h4>
            <p className="text-sm text-muted-foreground">Filtre faturas pelos parâmetros de URL</p>
          </div>
          <div className="grid gap-2">
            <div key="source" className="grid grid-cols-3 items-center gap-4">
              <Label htmlFor="utmSource">Source</Label>
              <Input
                value={tempFilters.utmSource}
                id="utmSource"
                onChange={handleInputChange}
                placeholder="utm_source"
                className="col-span-2 h-8"
              />
            </div>
            <div key="medium" className="grid grid-cols-3 items-center gap-4">
              <Label htmlFor="utmMedium">Medium</Label>
              <Input
                value={tempFilters.utmMedium}
                id="utmMedium"
                onChange={handleInputChange}
                placeholder="utm_medium"
                className="col-span-2 h-8"
              />
            </div>
            <div key="campaign" className="grid grid-cols-3 items-center gap-4">
              <Label htmlFor="utmCampaign">Campaign</Label>
              <Input
                value={tempFilters.utmCampaign}
                id="utmCampaign"
                onChange={handleInputChange}
                placeholder="utm_campaign"
                className="col-span-2 h-8"
              />
            </div>
            <div key="content" className="grid grid-cols-3 items-center gap-4">
              <Label htmlFor="utmContent">Content</Label>
              <Input
                value={tempFilters.utmContent}
                id="utmContent"
                onChange={handleInputChange}
                placeholder="utm_content"
                className="col-span-2 h-8"
              />
            </div>
            <div key="term" className="grid grid-cols-3 items-center gap-4">
              <Label htmlFor="utmTerm">Term</Label>
              <Input
                value={tempFilters.utmTerm}
                id="utmTerm"
                onChange={handleInputChange}
                placeholder="utm_term"
                className="col-span-2 h-8"
              />
            </div>
          </div>
          <div className="flex gap-2">
            {hasUtmParams && (
              <Button onClick={clearFilters} variant="ghost" className="w-full">
                Limpar filtros
              </Button>
            )}
            {hasChanges() && (
              <Button className="w-full" onClick={applyFilters}>
                Aplicar filtros
              </Button>
            )}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}
