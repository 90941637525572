import { cn, useIsMobile } from '@hub-la/shadcn'
import { Loader2 } from 'lucide-react'
import { useContext } from 'react'
import { AuthRedirectContext } from '../../providers/auth-redirect-provider'
import { Background } from './background.styled'

type AuthLayoutProps = {
  children: React.ReactNode
  showTerms?: boolean
}

const AuthLayout = ({ children, showTerms = true }: AuthLayoutProps) => {
  const isMobile = useIsMobile()
  const { isFirstLoading, parentLoaded } = useContext(AuthRedirectContext)

  const isLoading = parentLoaded && isFirstLoading

  if (isMobile) {
    return (
      <>
        {isLoading && (
          <div className="flex justify-center items-center">
            <Loader2 className="animate-spin text-white h-14 w-14" />
          </div>
        )}

        <div className={cn(isLoading && 'hidden pointer-events-none')}>{children}</div>
      </>
    )
  }

  return (
    <Background primaryColor="3b4d00" secondaryColor="d7ff60">
      {isLoading && (
        <div className="flex justify-center items-center">
          <Loader2 className="animate-spin text-white h-14 w-14" />
        </div>
      )}

      <div className={cn(isLoading && 'hidden pointer-events-none')}>{children}</div>

      {showTerms && (
        <div className="absolute bottom-8 w-full">
          <p className="text-sm text-center text-primary-foreground/75">
            Este site é protegido por reCAPTCHA e se aplicam os{' '}
            <a href="https://policies.google.com/terms" className="text-[#A8D90B] hover:text-[#8FB90A]">
              Termos de uso
            </a>{' '}
            e{' '}
            <a href="https://policies.google.com/privacy" className="text-[#A8D90B] hover:text-[#8FB90A]">
              Política de privacidade
            </a>{' '}
            do Google.
          </p>
        </div>
      )}
    </Background>
  )
}

export { AuthLayout }
