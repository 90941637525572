import { PermissionResource, useRoleplay } from '@hub-la/fe-roleplay'
import {
  Checkbox,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableEmptyState,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
} from '@hub-la/shadcn'
import times from 'lodash/times'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Member } from '../../../../domain/dtos/member'
import { MemberType } from '../../../../domain/enums/member-type'
import { Phone } from '../../../../domain/vos/phone'
import { GetCohortsContent } from '../../../../usecases/members/get-cohorts-content'
import { SubscriptionTag } from '../../../components'
import { AddFreeMemberModal } from '../../../components/add-free-member-modal/add-free-member-modal'
import { GenericConfirmModal } from '../../../components/generic-confirm-modal'
import { useGetCohortsList } from '../../../hooks/cohorts/use-get-cohorts-list'
import { useGetActiveMembers } from '../../../hooks/members/use-get-active-members'
import { useGetExportActiveMembers } from '../../../hooks/members/use-get-export-active-members'
import { usePutRemoveFreeSubscription } from '../../../hooks/members/use-put-remove-free-subscription'
import { Actions } from './actions'
import { ChangeParticipantsCohortsModal } from './change-participants-cohorts-modal'
import { MembersSelectedsBarActions } from './participants-selecteds-bar-actions'
import { RowParticipantActions } from './row-participants-actions'

export const SkeletonRow = () => (
  <TableRow>
    <TableCell>
      <Skeleton className="w-4/5 h-6" />
    </TableCell>

    <TableCell>
      <Skeleton className="w-4/5 h-6" />
    </TableCell>

    <TableCell>
      <Skeleton className="w-4/5 h-6" />
    </TableCell>

    <TableCell>
      <Skeleton className="w-4/5 h-6" />
    </TableCell>

    <TableCell>
      <Skeleton className="w-4/5 h-6" />
    </TableCell>
  </TableRow>
)

export const ParticipantsTable = () => {
  const { t } = useTranslation()
  const { productId } = useParams<{ productId: string }>()
  const { hasPermission } = useRoleplay()

  const getCohortsContent = new GetCohortsContent()

  const [types, setTypes] = useState<MemberType[]>([])
  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(25)

  // unique
  const [openChangeMemberCohortsModal, setOpenChangeMemberCohortsModal] = useState<Member | null>(null)
  // many members
  const [openChangeMembersCohortsModal, setOpenChangeMembersCohortsModal] = useState(false)

  const [selectedItems, setSelectedItems] = useState<Member[]>([])

  const [openAddFreeMemberModal, setOpenAddFreeMemberModal] = useState(false)
  const [openRemoveMemberModal, setOpenRemoveMemberModal] = useState<{
    isOpen: boolean
    userId: string
  }>({ isOpen: false, userId: '' })

  const { data: cohorts } = useGetCohortsList({
    productId,
    page,
    pageSize,
  })

  const {
    data: activeMembers,
    isFetching,
    refetch,
  } = useGetActiveMembers({
    productId,
    page,
    pageSize,
    types,
    search,
  })
  const { mutateAsync, isLoading: removeFreeMemberLoading } = usePutRemoveFreeSubscription({
    onSuccess: () => {
      setOpenRemoveMemberModal({
        isOpen: false,
        userId: '',
      })
      refetch()
    },
  })

  const isEmpty = !activeMembers?.items || activeMembers?.items.length === 0
  const canRenderEmptyState = !isFetching && isEmpty

  const { mutate: exportActiveMembers } = useGetExportActiveMembers()

  const handleExportActiveMembers = () => {
    const input = {
      productId,
      page: 1,
      pageSize: 5000,
      types,
      search,
    }
    exportActiveMembers(input)
  }

  const isCheckAll = selectedItems.length === activeMembers?.items.length

  const handleOnChange = (checked: boolean, value: string): void => {
    if (!activeMembers) {
      return
    }

    if (value === 'all' && !checked) {
      setSelectedItems([])
      return
    }

    if (value === 'all') {
      setSelectedItems(activeMembers?.items)
      return
    }

    setSelectedItems((prevSelectedItems) => {
      const selectedMember = activeMembers?.items.find((member) => member.id === value)

      if (!selectedMember) {
        return prevSelectedItems
      }

      if (checked) {
        return [...prevSelectedItems, selectedMember]
      }

      return prevSelectedItems.filter((member) => member.id !== selectedMember.id)
    })
  }

  return (
    <div className="mt-3 flex flex-col gap-4">
      <Actions
        onAddMember={() => setOpenAddFreeMemberModal(true)}
        onSearchChange={(search) => setSearch(search)}
        onSubscriptionTypeChange={(types) => setTypes(types as MemberType[])}
        onExportActiveMembers={handleExportActiveMembers}
      />

      <AddFreeMemberModal
        open={openAddFreeMemberModal}
        onClose={() => setOpenAddFreeMemberModal(false)}
        onSubmit={() => setOpenAddFreeMemberModal(false)}
        productId={productId}
        isEvent
      />

      <p className="text-sm mb-3">{activeMembers?.total} membros</p>

      <div className="bg-background rounded-lg border">
        <Table>
          <TableHeader>
            <TableRow className="hover:bg-background">
              <TableHead className="w-16">
                <Checkbox
                  checked={isCheckAll}
                  onCheckedChange={(checked) => handleOnChange(checked as boolean, 'all')}
                />
              </TableHead>

              <TableHead>{t('tabs.participants.table.member.label')}</TableHead>

              <TableHead>{t('tabs.participants.table.email.label')}</TableHead>

              <TableHead>{t('tabs.participants.table.phoneNumber.label')}</TableHead>

              <TableHead>{t('tabs.participants.table.cohorts.label')}</TableHead>

              <TableHead></TableHead>

              <TableHead></TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {isFetching
              ? times(3, (i) => <SkeletonRow key={`skeleton-row-${i}`} />)
              : (activeMembers?.items || []).map((member) => (
                  <TableRow key={member?.id}>
                    <TableCell>
                      <Checkbox
                        checked={selectedItems.some((it) => it.id === member?.id)}
                        onCheckedChange={(checked) => handleOnChange(checked as boolean, member?.id)}
                      />
                    </TableCell>

                    <TableCell>
                      <div className="flex flex-col">
                        {member?.name}

                        <span className="text-xs text-muted-foreground">{member?.id}</span>
                      </div>
                    </TableCell>

                    <TableCell className="text-muted-foreground">{member?.email ?? '–'}</TableCell>

                    <TableCell className="text-muted-foreground">
                      {member?.phoneNumber ? Phone.build(member.phoneNumber).getFormattedNumber() : '–'}
                    </TableCell>

                    <TableCell className="text-muted-foreground">
                      {getCohortsContent.execute(member.cohortIds, cohorts?.items ?? [])}
                    </TableCell>

                    <TableCell>
                      <SubscriptionTag type={member.type} />
                    </TableCell>

                    <TableCell>
                      <RowParticipantActions
                        type={member.type}
                        onRemoveFreeMember={() =>
                          setOpenRemoveMemberModal({
                            isOpen: true,
                            userId: member.id,
                          })
                        }
                        onChangeCohort={() => {
                          setOpenChangeMemberCohortsModal(member)
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </div>

      {canRenderEmptyState && <TableEmptyState title={t('participants.emptyState')} />}

      {!isEmpty && (
        <TablePagination
          page={page}
          setPage={setPage}
          lastPage={activeMembers?.lastPage ?? 1}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}

      {hasPermission(PermissionResource.PRODUCTS_MANAGE_MEMBERS) && (
        <GenericConfirmModal
          title={t('tabs.participants.removeFreeMemberModal.title')}
          description={t('tabs.participants.removeFreeMemberModal.description')}
          confirmText={t('tabs.participants.removeFreeMemberModal.confirmText')}
          isOpen={openRemoveMemberModal?.isOpen}
          onCancel={() => {
            setOpenRemoveMemberModal({
              isOpen: false,
              userId: '',
            })
          }}
          onDelete={() => {
            mutateAsync({
              productId,
              userId: openRemoveMemberModal?.userId,
            })
          }}
          isLoading={removeFreeMemberLoading}
        />
      )}

      <MembersSelectedsBarActions
        selectedItems={selectedItems}
        setOpenChangeMembersCohortsModal={setOpenChangeMembersCohortsModal}
        setSelectedItems={setSelectedItems}
      />

      <ChangeParticipantsCohortsModal
        isOpen={openChangeMembersCohortsModal}
        productId={productId}
        membersSelected={selectedItems}
        onClose={() => setOpenChangeMembersCohortsModal(false)}
      />

      {openChangeMemberCohortsModal && (
        <ChangeParticipantsCohortsModal
          isOpen={!!openChangeMemberCohortsModal}
          productId={productId}
          membersSelected={[openChangeMemberCohortsModal]}
          onClose={() => setOpenChangeMemberCohortsModal(null)}
        />
      )}
    </div>
  )
}
