import { useTranslation } from "react-i18next"
import { formatCurrency } from "../../../../domain/vos/format-currency"
import { useGetSmartInstallmentCyclesSummarie } from "../../../hooks/use-get-smart-installment-cycles-summarie"

import { CardInfoBox } from "./card-info-box"
export const SmartInstallmentsSummarie = () => {
  const { data, isLoading } = useGetSmartInstallmentCyclesSummarie()

  const { t } = useTranslation()

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
      <CardInfoBox
        title={t('summarie.paid.title')}
        current={formatCurrency(data?.amountTotalPaidCents ?? 0)}
        isLoading={isLoading}
      />
      <CardInfoBox
        title={t('summarie.overdue.title')}
        current={formatCurrency(data?.amountTotalOverdueCents ?? 0)}
        isLoading={isLoading}
      />
      <CardInfoBox
        title={t('summarie.draft.title')}
        current={formatCurrency(data?.amountTotalDraftCents ?? 0)}
        isLoading={isLoading}
      />
    </div>
  )
}
