export const pt = {
  summarie: {
    paid: {
      title: 'Total pago',
      description: 'placeholder'
    },
    overdue: {
      title: 'Atrasado',
      description: 'placeholder'
    },
    draft: {
      title: 'Agendado',
      description: 'placeholder'
    }
  },
  smartInstallmentCyclesEmpty: "Você não possui parcelamentos em aberto",
  title: 'Você possui {{ amount }} em parcelas em aberto',
  paidInstallments: '{{installment}}/{{installments}} parcelas pagas',
  nextDueAt: 'Próximo vencimento {{date}}',
  scheduleOffAt: 'Atrasado desde {{date}}',
  back: 'Voltar',
  oldExperience: {
    title: 'Você está usando a nova experiência de alunos.',
    cta: 'Clique aqui para usar a antiga experiência.',
  },
  newExperience: {
    title: 'Há uma nova experiência de alunos disponível na plataforma!',
    cta: 'Clique aqui para utilizá-la.',
  },
  smartInstallment: {
    overdueInstallments: 'Pagamentos pendentes',
    cta: 'Compras parceladas'
  },
}
