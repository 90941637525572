import { Button, Label, Popover, PopoverContent, PopoverTrigger } from '@hub-la/shadcn'
import { Palette } from 'lucide-react'
import React, { useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import { Controller, FieldPath, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { OfferSchemaValidationType } from '../validations/offer-validation'

interface ColorPickerProps {
  name: FieldPath<OfferSchemaValidationType>
}

export const ColorPicker: React.FC<ColorPickerProps> = ({ name }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const { control } = useFormContext<OfferSchemaValidationType>()
  const color = useWatch({ name, control })

  return (
    <div className="mt-4 space-y-1.5">
      <Label>{t('offer.checkoutTab.preferences.colorPicker')}</Label>
      <div className="flex items-center space-x-2">
        <div
          className="w-full max-w-[140px] h-10 rounded border border-gray-300 flex items-center justify-center"
          style={{ backgroundColor: color ?? '#f20000' }}
        >
          <span className="text-primary-foreground text-shadow" style={{ textShadow: '1px 1px 1px rgba(0, 0, 0, 1)' }}>
            {color}
          </span>
        </div>
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button variant="outline" size="icon" type="button">
              <Palette className="h-4 w-4" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0">
            <Controller
              name={name}
              control={control}
              render={({ field }) => <HexColorPicker color={field.value} onChange={field.onChange} />}
            />
          </PopoverContent>
        </Popover>
      </div>
    </div>
  )
}
