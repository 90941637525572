export const INTEGRATION_CATEGORIES_LIST = [
  {
    name: 'Ferramentas de Integração e Automação',
    description: 'Ferramentas de automatizações para facilitar e potencializar a gestão do seu negócio.',
    integrations: [
      {
        title: 'Webhooks',
        description: 'Integração com plataformas externas.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fwebhook.png?alt=media',
        enabled: true,
        provider: 'webhook',
      },
      // {
      //   title: 'Pluga',
      //   description: 'Integração e automação de processos entre diferentes aplicações.',
      //   icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fpluga.png?alt=media',
      //   enabled: false,
      //   provider: 'pluga',
      // },
      // {
      //   title: 'Make.com',
      //   description: 'Automação e integração com criação de fluxos de trabalho entre aplicativos.',
      //   icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fmakecom.png?alt=media',
      //   enabled: false,
      //   provider: 'makecom',
      // },
      {
        title: 'Zapier',
        description: 'Conecta aplicativos e automatiza fluxos de trabalho entre eles.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fzapier.png?alt=media',
        enabled: true,
        provider: 'zapier',
      },
    ],
  },
  {
    name: 'Plataformas de Automação de Marketing',
    description: 'Automatize suas ações de marketing e venda.',
    integrations: [
      {
        title: 'ActiveCampaign',
        description: 'Automação de marketing com funcionalidades de CRM integradas.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2FRectangle%202.png?alt=media',
        enabled: true,
        provider: 'active-campaign',
      },
      {
        title: 'LeadLovers',
        description: 'Automação de marketing com foco em funis de vendas e gestão de leads.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Flead_lovers.png?alt=media',
        enabled: true,
        provider: 'leadlovers',
      },
      // {
      //   title: 'Mailchimp',
      //   description: 'Focada em e-mail marketing e automação, com algumas funcionalidades de CRM.',
      //   icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fmailchimp.png?alt=media',
      //   enabled: false,
      //   provider: 'mailchimp',
      // },
      {
        title: 'RD Station',
        description: 'Automação de marketing para gestão de leads e campanhas.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Frdstation.png?alt=media',
        enabled: true,
        provider: 'rdstation',
      },
    ],
  },
  {
    name: 'Plataformas de CRM',
    description: 'Gerencie seus clientes e leads de forma eficiente criando funis de vendas.',
    integrations: [
      // {
      //   title: 'Clint',
      //   description: 'CRM para gestão de relacionamento com clientes e automação de vendas.',
      //   icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fclint.png?alt=media',
      //   enabled: false,
      //   provider: 'clint',
      // },
      {
        title: 'HubSpot',
        description: 'Automação de marketing e CRM com ferramentas para inbound marketing e vendas',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fhubspot.png?alt=media',
        enabled: true,
        provider: 'hubspot',
      },
    ],
  },
  {
    name: 'Ferramenta para Gestão de Leads e Vendas',
    description: 'Gerencie seus leads e vendas de forma eficiente.',
    integrations: [
      {
        title: 'SellFlux',
        description: 'Automação de vendas e marketing',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fsell-flux.png?alt=media',
        enabled: true,
        provider: 'sell-flux',
      },
      {
        title: 'GDigital',
        description: 'Automação, marketing e CRM',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fg-digital.png?alt=media',
        enabled: true,
        provider: 'g-digital',
      },
    ],
  },
  {
    name: 'Ferramentas de Comunicação e Mensagens',
    description: 'Ferramentas para comunicação com seus clientes.',
    integrations: [
      {
        title: 'ZapCerto',
        description: 'Disparos em Massa, Automações, Marketing Conversacional.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fzapcerto.png?alt=media',
        enabled: true,
        provider: 'zapcerto',
      },
    ],
  },
  {
    name: 'Plataformas de Faturamento',
    description: 'Evite trabalho manuais e tenha NFS-e emitidas de forma automatizada.',
    integrations: [
      {
        title: 'eNotas',
        description: 'Emissão de notas fiscais eletrônicas e integração com sistemas ERP.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fenotas.png?alt=media',
        enabled: true,
        provider: 'enotas',
      },
      // {
      //   title: 'NFe.io',
      //   description: 'Emissão de NFS-e com foco em automação de processos fiscais.',
      //   icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fnfeio.png?alt=media',
      //   enabled: false,
      //   provider: 'nfeio',
      // },
      // {
      //   title: 'Notazz',
      //   description: 'Emissão e gestão de notas fiscais eletrônicas e documentos fiscais.',
      //   icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fnotazz.png?alt=media',
      //   enabled: false,
      //   provider: 'notazz',
      // },
      {
        title: 'Omie',
        description: 'Ferramenta de emissão automática de NFS-e.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fomie.png?alt=media',
        enabled: true,
        provider: 'omie',
      },
      // {
      //   title: 'Spedy',
      //   description: 'Emissão de NFS-e, com suporte para controle fiscal e contábil.',
      //   icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fspedy.png?alt=media',
      //   enabled: false,
      //   provider: 'spedy',
      // },
      {
        title: 'ContaAzul',
        description: 'Contabilidade online.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fconta-azul.png?alt=media',
        enabled: true,
        provider: 'contaazul',
      },
    ],
  },
  {
    name: 'Ads e Analytics',
    description: 'Ganhe mais insights sobre os seus produtos.',
    integrations: [
      {
        title: 'Meta Pixel',
        description: 'Ferramenta para mensurar, otimizar e criar suas campanhas de anúncios na Meta.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fmeta_pixel.png?alt=media',
        enabled: true,
        provider: 'meta-pixel',
      },
      {
        title: 'Google Tag Manager',
        description: 'Ferramenta de gerenciamento de tags do Google.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fgoogle_tag_manager.png?alt=media',
        enabled: true,
        provider: 'google-tag-manager',
      },
    ],
  },
  {
    name: 'Notas Fiscais',
    description: 'Evite trabalho manuais e tenha NFSe emitidas automaticamente',
    integrations: [
      {
        title: 'eNotas',
        description: 'Ferramenta de emissão automática de NFSe.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fenotas.png?alt=media',
        enabled: true,
        provider: 'enotas',
      },
      {
        title: 'Omie',
        description: 'Ferramenta de emissão automática de NFSe.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fomie.png?alt=media',
        enabled: true,
        provider: 'omie',
      },
    ],
  },
  {
    name: 'Plataformas de Áreas de Membros e Cursos Online',
    description: 'Venda pela Hubla e entregue seu conteúdo através de outra plataforma',
    integrations: [
      // {
      //   title: 'Cademí',
      //   description: 'Plataforma para criação e gestão de cursos online.',
      //   icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fcademi.png?alt=media',
      //   enabled: true,
      //   provider: 'cademi',
      // },
      {
        title: 'MemberKit',
        description: 'Plataforma para criação, gestão de áreas de membros e gamificação.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fmemberkit.jpg?alt=media',
        enabled: true,
        provider: 'memberkit',
      },
      {
        title: 'TheMembers',
        description: 'Solução para áreas de membros e comunidades.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fthemembers.png?alt=media',
        enabled: true,
        provider: 'themembers',
      },
      {
        title: 'Tutory',
        description: 'Plataforma de ensino online.',
        icon: 'https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Ftutory.png?alt=media',
        enabled: false,
        provider: 'tutory',
      },
    ],
  },
]
