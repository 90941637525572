export const pt = {
  title: 'Vendas',
  status: {
    draft: 'Agendada',
    pending: 'Em aberto',
    paid: 'Paga',
    expired: 'Não paga',
    off_schedule: 'Atrasada',
    refunded: 'Reembolsada',
    in_protest: 'Contestada',
    chargeback: 'Chargeback',
    canceled: 'Cancelado',
  },
  copyClipboard: {
    copyInvoiceId: 'Copiar ID do parcelamento',
    invoiceIdCopied: 'ID do parcelamento copiado',
    copyOfferId: 'Copiar ID da oferta',
    offerIdCopied: 'ID da oferta copiado',
    copyEventId: 'Copiar link da página de pagamento',
    eventIdCopied: 'link da página de pagamento copiado',
    copyRefundId: 'Copiar link de detalhes do reembolso',
    refundIdCopied: 'Link de detalhes do reembolso copiado',
  },
  tabs: {
    invoices: 'Faturas',
    smartInstallments: 'Parcelamento inteligente',
  },
  smartInstallmentStatus: {
    on_schedule: 'Em dia',
    off_schedule: 'Atrasado',
    canceled: 'Cancelado',
    aborted: 'Cancelado',
    completed: 'Concluído',
  },
  variation: 'vs. {{previous}} período anterior',
  selectPeriod: {
    hourly: 'Por hora',
    daily: 'Diário',
    weekly: 'Semanal',
    monthly: 'Mensal',
  },
  totalSale: {
    title: 'Valor total das faturas',
    description: 'Valor total das faturas criadas durante o período.',
  },
  netRevenue: {
    title: 'Receita líquida das faturas',
    description: 'Valor total recebido das faturas criadas durante o período.',
  },
  totalInvoices: {
    title: 'Número de faturas',
    description: 'Total de faturas criadas durante o período.',
  },
  statusPicker: {
    paid: 'Pagas',
    draft: 'Em aberto',
    pending: 'Em aberto',
    expired: 'Não pagas',
    refunded: 'Reembolsadas',
    in_protest: 'Disputadas',
    chargeback: 'Chargebacks',
    canceled: 'Canceladas',
    all: 'Tudo',
  },
  smartInstallmentsSummaries: {
    count: {
      title: 'Vendas recuperadas',
      description:
        'Número total de vendas que foram recusadas por falta de limite e foram recuperadas pelo parcelamento inteligente.',
    },
    total: {
      title: 'Total parcelado',
      description: 'Soma do valor total das parcelas atrasadas, pagas e agendadas',
    },
    totalPaid: {
      title: 'Total pago',
      description:
        'Soma do valor total de todas parcelas com status pagas das vendas recuperadas pelo parcelamento inteligente.',
    },
    totalScheduled: {
      title: 'Total agendado',
      description:
        'Soma do valor total das parcelas das vendas recuperadas pelo parcelamento inteligente que estão agendadas para pagamento em uma data futura.',
    },
    totalUnpaid: {
      title: 'Total atrasado',
      description:
        'Soma do valor total das parcelas de vendas recuperadas pelo parcelamento inteligente que não tiveram pagamento bem sucedido e já passaram da sua data de vencimento.',
    },
  },
  smartInstallmentsTable: {
    tooltip: {
      total: 'Valor total da assinatura.',
      status: 'Status atual do parcelamento.',
      installment: 'Status atual do parcelamento.',
      overdue_installments: 'Total de parcelas com status não pagas.',
      draft_installments: 'Total de parcelas com status agendadas.',
      paid_installments: 'Total de parcelas com status pagas.',
      client: 'Nome completo do cliente.',
      product: 'Produto principal do parcelamento.',
    },
  },
  smartInstallmentsInvoiceTable: {
    tooltip: {
      total: 'Valor total da fatura criada.',
      status: 'Status atual da fatura criada.',
      id: 'Identificador único da fatura criada.',
      createdAt: '',
      dueDate: '',
    },
  },
  table: {
    pageSize: '{{total}} por página',
  },
  empty: 'Nenhum resultado encontrado',
  emptySubtitle: 'Ajuste seus critérios de busca ou redefina os filtros para obter mais resultados.',
  filters: {
    autocomplete: {
      noOptions: 'Não há opções disponíveis',
      loading: 'Carregando...',
      products: 'Produtos',
    },
    dateRangeBy: {
      createdAt: 'Data de criação',
      nextDueAt: 'Próximo vencimento',
    },
  },
  paymentMethod: {
    credit_card: 'Cartão de crédito',
    pix: 'Pix',
    boleto: 'Boleto',
  },
  invoiceType: {
    sell: 'Compra',
    renewal: 'Renovação',
    upgrade: 'Upgrade',
  },
  invoiceTable: {
    tooltip: {
      total: 'Valor total da fatura criada.',
      status: 'Status atual da fatura criada.',
      id: 'Identificador único da fatura criada.',
      client: 'Nome completo do cliente.',
      product: 'Produto principal comprado pelo cliente.',
    },
  },
  subscriptionStatus: {
    active: 'Ativa',
    canceled: 'Cancelada',
    inactive: 'Inativa',
    incomplete: 'Incompleto',
    trial: 'Período de teste',
  },
  planType: {
    monthly: 'Plano mensal',
    quarterly: 'Plano trimestral',
    semiannually: 'Plano semestral',
    annually: 'Plano anual',
  },
  plan: {
    monthly: 'mês',
    quarterly: 'trimestre',
    semiannually: 'semestre',
    annually: 'ano',
  },
  description: {
    product: '{{productName}}',
    productInstallment: '1x parcela de {{productName}}',
    discountCents: 'Cupom de desconto - {{couponCode}}',
    installmentFeeCents: 'Taxa de parcelamento',
    totalCents: 'Valor do pagamento',
    platformAmountCents: 'Taxa Hubla',
    installmentFeeCostCents: 'Custo de taxa de parcelamento',
    affiliate: 'Comissão do afiliado - {{name}}',
    subtotalCents: 'Valor líquido',
    coProducer: 'Comissão do co-produtor - {{name}}',
    netCents: 'Sua comissão de {{role}}',
  },
  role: {
    platform: 'plataforma',
    gateway: 'gateway',
    seller: 'produtor',
    partner: 'co-produtor',
    affiliate: 'afiliado',
  },
  details: {
    installments: `{{installments}}x {{installmentCostsPayer}} juros`,
  },
  invoiceFeedback: {
    chargeback:
      'A Hubla normalmente realizada a contestação dos chargebacks automaticamente, caso tenha dúvidas entre em contato com o suporte para mais informações.',
    refunded: 'Motivo: {{reason}} - ID do reembolso: {{id}}',
  },
  exportXlsx: {
    email: 'Email para envio do relatório',
    title: 'Exportar faturas',
    smartInstallmentsTitle: 'Exportar parcelamento inteligente',
    cancel: 'Cancelar',
    confirm: 'Exportar',
    getIt: 'Entendido',
    noOptions: 'Não há opções disponíveis',
    loading: 'Carregando...',
    offers: 'Ofertas',
    successMessage: `A sua planilha está sendo gerada e será enviada no email {{email}} quando estiver pronta. Esta ação pode demorar de 5-10min dependendo do período de data selecionado.`,
    conflictErrorMessage:
      'Já existe uma exportação em andamento para o período selecionado. Verifique seu email. Caso não tenha recebido, entre em contato com o suporte.',
    longPeriodErrorMessage: 'O período selecionado é muito longo. Selecione um período menor.',
    longPeriodInputErrorMessage: 'Selecione um período menor',
    generalErrorMessage: 'Erro ao exportar a planilha. Entre em contato com o suporte.',
  },
  tracking: {
    title: 'UTM Tracking',
    source: 'Origem',
    medium: 'Mídia',
    campaign: 'Campanha',
    content: 'Conteúdo',
    term: 'Termo',
  },
  knowMore: 'Saiba mais sobre <0>vendas</0>',
  errors: {
    unkwown: 'Algo deu errado. Tente novamente mais tarde.',
    forbidden: {
      smartInstallment: 'Você não tem permissão para acessar este parcelamento.',
      invoice: 'Você não tem permissão para acessar esta fatura.',
    },
    'no-result': 'Fatura não encontrada.',
    'not-found': 'Fatura não encontrada.',
    'refund-not-paid': 'Você só pode reembolsar faturas pagas.',
    'refund-payment-method-not-allowed': 'Método de pagamento boleto não aceita reembolso.',
    'refund-deadline-exceeded': 'Tempo limite de reembolso excedido (180 dias para cartão e 90 dias para PIX).',
    'refund-not-enough-balance': 'Você não tem saldo disponível para reembolso.',
  },
  success: 'Ação realizada com sucesso!',
  smartInstallment: 'Parcelamento inteligente',
  cardBrand: {
    0: '',
    1: '',
    2: 'Mastercard',
    3: 'Visa',
    4: 'Elo',
    5: 'Amex',
  },
  'smart-installment': {
    timeline: {
      title: {
        payment_page_created: 'Fatura criada para parcela {{installment}} / {{installments}}. <0>Ver fatura</0>',
        created: 'Parcelamento criado',
        installment_overdue: 'Parcela {{installment}} / {{installments}} esta atrasada. <0>Ver fatura</0>',
        overdue: 'Parcelamento atrasado',
        disputed: 'Parcela {{installment}} / {{installments}} foi disputada. <0>Ver fatura</0>',
        error: 'Erro no pagamento da parcela {{installment}} / {{installments}}. <0>Ver fatura</0>',
        canceled: 'Parcelamento cancelado',
        refunded: 'Parcela {{installment}} / {{installments}} reembolsada. <0>Ver fatura</0>',
        paid: 'Parcela {{installment}} / {{installments}} paga com sucesso. <0>Ver fatura</0>',
        completed: 'Parcelamento concluído',
        regularized: 'Parcelamento regularizado e todos os pagamentos estão em dia',
      },
      description: {
        completed: 'Todas as parcelas foram pagas com sucesso. O parcelamento foi concluído.',
        disputed: 'Faturas disputadas podem virar um chargeback.',
        canceled: 'O parcelamento foi cancelado e não haverá mais cobranças futuras.',
        payment_page_created: 'O cliente deve efetuar o pagamento até dia {{dueDate}}',
        regularized: 'Todas parcelas em atraso foram pagas',
      },
    },
  },
  invoice: {
    timeline: {
      title: {
        payment_page_created: 'A <0>página de pagamento da fatura</0> foi criada',
        created: 'Fatura criada',
        payment_pending: 'Pagamento pendente',
        payment_created: 'Pagamento criado',
        payment_captured: 'Pagamento bem sucedido',
        payment_error: 'Falha no pagamento',
        payment_refused: 'Pagamento recusado',
        payment_authorized: 'Pagamento autorizado',
        payment_canceled: 'Pagamento bloqueado pelo anti-fraude devido ao alto risco',
        payment_refunded: '<0>Pagamento reembolsado</0>',
        payment_partially_refunded: 'Pagamento parcialmente reembolsado',
        payment_disputed: 'Pagamento em disputa',
        payment_chargeback: 'Pagamento estornado',
        payment_expired: 'Fatura expirada',
        payment_partially_captured: 'Pagamento parcialmente capturado',
        payment_unspecified: 'Estado de pagamento não especificado',
        smart_installment_started: 'Recuperação via Parcelamento Inteligente iniciado',
      },
      description: {
        antifraud_canceled: 'Para saber mais sobre o sistema de anti-fraude, entre em contato com o suporte',
        smart_installment_succeeded: 'Recuperado via Parcelamento Inteligente',
        new_payment_method: {
          0: 'Método de pagamento alterado',
          1: 'Método de pagamento alterado para cartão de crédito',
          2: 'Método de pagamento alterado para Pix',
          3: 'Método de pagamento alterado para boleto',
        },
      },
    },
  },
  // TODO: Remove after the changes related to event name are deployed
  timeline: {
    title: {
      payment_page_created: 'A <0>página de pagamento da fatura</0> foi criada',
      created: 'Fatura criada',
      payment_pending: 'Pagamento pendente',
      payment_created: 'Pagamento criado',
      payment_captured: 'Pagamento bem sucedido',
      payment_error: 'Falha no pagamento',
      payment_refused: 'Pagamento recusado',
      payment_authorized: 'Pagamento autorizado',
      payment_canceled: 'Pagamento bloqueado pelo anti-fraude devido ao alto risco',
      payment_refunded: 'Pagamento reembolsado',
      payment_partially_refunded: 'Pagamento parcialmente reembolsado',
      payment_disputed: 'Pagamento em disputa',
      payment_chargeback: 'Pagamento estornado',
      payment_expired: 'Fatura expirada',
      payment_partially_captured: 'Pagamento parcialmente capturado',
      payment_unspecified: 'Estado de pagamento não especificado',
      smart_installment_started: 'Recuperação via Parcelamento Inteligente iniciado',
    },
    description: {
      antifraud_canceled: 'Para saber mais sobre o sistema de anti-fraude, entre em contato com o suporte',
      smart_installment_succeeded: 'Recuperado via Parcelamento Inteligente',
      new_payment_method: {
        0: 'Método de pagamento alterado',
        1: 'Método de pagamento alterado para cartão de crédito',
        2: 'Método de pagamento alterado para Pix',
        3: 'Método de pagamento alterado para boleto',
      },
    },
  },
  contentCopiedSuccessfully: 'Copiado com sucesso!',
  invoiceDetail: {
    chargeDetail: {
      title: 'Detalhes da cobrança',
      invoiceId: 'ID da fatura',
      createdAt: 'Data de criação',
      paidAt: 'Data de pagamento',
      paymentMethod: 'Método de pagamento',
      cardExpirationDate: 'Vencimento',
      nameInCard: 'Nome no cartão',
      installmentsQuantity: 'Quantidade de parcelas',
      document: 'Documento',
      address: 'Endereço',
      origin: 'Origem',
    },
  },
  paymentMethods: {
    unknown: 'Desconhecido',
    creditCard: 'Cartão de crédito',
    pix: 'Pix',
    boleto: 'Boleto',
  },
  search: 'Buscar...',
  datepicker: {
    placeholder: 'Período',
  },
}
