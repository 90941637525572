import { ImageUploader } from '@hub-la/fe-asset'
import { useFeatureFlag } from '@hub-la/fe-core-feature-flag'
import {
  BannerLayout,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Tabs,
  TabsList,
  TabsTrigger,
} from '@hub-la/shadcn'
import { ChevronsRightLeft, Expand } from 'lucide-react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { BannerDisplayMode } from '../../../../../../domain/enums/banner-display-mode.enum'
import { BannerSize } from '../../../../../../domain/enums/banner-size.enum'
import { BannerHint } from '../../../../../../usecases/banner-hint'
import { CustomCollapsible } from '../../../../../components/custom-collapsible'
import { OfferSchemaValidationType } from '../../../../../validations/offer-validation'

export const BannerCollapsible = () => {
  const { t } = useTranslation()

  const { productId, offerId } = useParams<{ productId: string; offerId: string }>()
  const isCheckoutV4EnabledToOffer = useFeatureFlag().isFlagEnabled('isCheckoutV4EnabledToOffer', {
    offerId,
  })
  const { watch } = useFormContext<OfferSchemaValidationType>()
  const bannerSize = watch('checkout.bannerSize')
  const bannerDisplayMode = watch('checkout.bannerDisplayMode')
  const sideBannerDesktopUrl = watch('checkout.sideBannerDesktopUrl')

  const hint = new BannerHint(bannerDisplayMode, bannerSize, sideBannerDesktopUrl).execute()

  return (
    <CustomCollapsible title={t(`offer.checkoutTab.customization.banner.title`)} Icon={BannerLayout}>
      <div className="grid gap-4">
        {isCheckoutV4EnabledToOffer && (
          <div className="grid gap-4">
            <Controller
              name="checkout.bannerDisplayMode"
              render={({ field }) => <BannerLayoutSelector value={field.value} onChange={field.onChange} />}
            />
            <Controller
              name="checkout.bannerSize"
              render={({ field }) => <BannerSizeSelector value={field.value} onChange={field.onChange} />}
            />
          </div>
        )}
        <div className="flex justify-between">
          <div>
            <h3 className="text-sm font-medium mb-1">{t('offer.checkoutTab.customization.banner.mobile.label')}</h3>
            <p className="text-sm text-muted-foreground">
              {t('offer.checkoutTab.customization.banner.mobile.description', { size: hint.mobile })}
            </p>
          </div>
          <Controller
            name="checkout.bannerMobileUrl"
            render={({ field }) => (
              <ImageUploader
                name={field.name}
                path={`/checkout/${productId}`}
                className="w-24 h-14"
                value={field.value}
                onChange={(file) => field.onChange(file?.URL)}
                obfuscateFileName
              />
            )}
          />
        </div>
        <div className="flex justify-between">
          <div>
            <h3 className="text-sm font-medium mb-1">{t('offer.checkoutTab.customization.banner.desktop.label')}</h3>
            <p className="text-sm text-muted-foreground">
              {t('offer.checkoutTab.customization.banner.desktop.description', { size: hint.desktop })}
            </p>
          </div>
          <Controller
            name="checkout.bannerDesktopUrl"
            render={({ field }) => (
              <ImageUploader
                name={field.name}
                path={`/checkout/${productId}`}
                className="w-24 h-14"
                value={field.value}
                onChange={(file) => field.onChange(file?.URL)}
                obfuscateFileName
              />
            )}
          />
        </div>
      </div>
    </CustomCollapsible>
  )
}

interface BannerSizeSelectorProps {
  onChange: (size: BannerSize) => void
  value: BannerSize
}

export const BannerSizeSelector = (props: BannerSizeSelectorProps) => {
  const { t } = useTranslation()

  return (
    <div>
      <h3 className="text-sm font-medium mb-1">{t('offer.checkoutTab.customization.banner.size.label')}</h3>
      <Tabs value={props.value} onValueChange={(value) => props.onChange(value as BannerSize)} className="max-w-max">
        <TabsList className="flex">
          <TabsTrigger value={BannerSize.SMALL}>{t('offer.checkoutTab.customization.banner.size.small')}</TabsTrigger>
          <TabsTrigger value={BannerSize.MEDIUM}>{t('offer.checkoutTab.customization.banner.size.medium')}</TabsTrigger>
          <TabsTrigger value={BannerSize.LARGE}>{t('offer.checkoutTab.customization.banner.size.large')}</TabsTrigger>
        </TabsList>
      </Tabs>
    </div>
  )
}

interface BannerLayoutSelectorProps {
  onChange: (layout: BannerDisplayMode) => void
  value: BannerDisplayMode
}

export const BannerLayoutSelector = (props: BannerLayoutSelectorProps) => {
  const { t } = useTranslation()

  return (
    <div>
      <h3 className="text-sm font-medium mb-1">{t('offer.checkoutTab.customization.banner.layout.label')}</h3>
      <Select onValueChange={props.onChange} value={props.value}>
        <SelectTrigger className="w-full">
          <SelectValue placeholder={t('offer.checkoutTab.customization.banner.layout.label')} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value={BannerDisplayMode.FULL_SCREEN}>
              <span className="flex items-center gap-2">
                <Expand className="w-4 h-4" />
                {t(`offer.checkoutTab.customization.banner.layout.full`)}
              </span>
            </SelectItem>
            <SelectItem value={BannerDisplayMode.FIT_CONTENT}>
              <span className="flex items-center gap-2">
                <ChevronsRightLeft className="w-4 h-4" />
                {t(`offer.checkoutTab.customization.banner.layout.fitContent`)}
              </span>
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  )
}
