import { Card, cn, Stream } from "@hub-la/shadcn"
import { ChevronRight } from "lucide-react"
import { formatCurrency } from "../../../../domain/vos/format-currency"
import { Invoice } from "../../../../domain/dtos/get-in-progress-smart-installment-cycles"
import { useTranslation } from "react-i18next"
import { format } from "date-fns"

export const SmartInstallmentCycle = ({ invoice }: { invoice: Invoice }) => {
  const { t } = useTranslation()

  const date = invoice.scheduleOffAt ?
    t('scheduleOffAt', { date: format(new Date(invoice.scheduleOffAt), 'dd MMM. yyyy') }) :
    t('nextDueAt', { date: format(new Date(invoice.nextDueAt), 'dd MMM. yyyy') })

  return (
    <Card className="text-sm px-6 py-4 flex flex-col md:flex-row md:items-center gap-4">
      <div>
        <div className={cn("rounded-full max-w-max p-4", invoice.scheduleOffAt ? 'bg-destructive/20 text-red-400' : 'bg-muted')}>
          <Stream className="h-5 w-5" />
        </div>
      </div>
      <div className="flex-1 flex flex-col md:flex-row md:items-center jusity-between gap-4">
        <div className="flex-1">
          <h4 className="font-medium">{invoice.productName}</h4>
          <p className="text-muted-foreground">{t('paidInstallments', { installment: invoice.installment, installments: invoice.installments })}</p>
          <p className="text-muted-foreground">{date}</p>
        </div>
        <div>
          <h4 className="font-medium">{formatCurrency(invoice.remainingTotalAmount / 100)}</h4>
          <p className="text-muted-foreground md:text-right">Em aberto</p>
        </div>
        <ChevronRight className="h-5 w-5 hidden md:block" />
      </div>
    </Card>
  )
}
