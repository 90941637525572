import { Tabs, TabsList, TabsTrigger } from '@hub-la/shadcn'
import { Banknote, CircleDollarSign, RefreshCwIcon } from 'lucide-react'
import { useCallback } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { paymentMethodsFieldOptions } from '../../../../../domain/constants/payment-methods-field-options'
import { PriceType } from '../../../../../domain/enums/price-type.enum'
import { OfferSection } from '../../../../components/offer-section'
import { OfferSchemaValidationType } from '../../../../validations/offer-validation'
import { PaymentMethodSelect } from '../../checkout/components/payment-method-select'
import { Options } from './components/options'
import { PriceConfiguration } from './components/price-configuration'

const PricingContent = () => {
  const { t } = useTranslation()

  const { setValue, clearErrors } = useFormContext<OfferSchemaValidationType>()

  const priceType = useWatch({
    name: 'pricing.priceType',
  })
  const isPublished = useWatch({
    name: 'isPublished',
  })
  const paymentMethods = useWatch({
    name: 'checkout.paymentMethods',
  })

  const isRecurringPrice = priceType === 'recurring'

  const handleSelectPriceType = useCallback(
    (priceType: PriceType) => {
      setValue('pricing.priceType', priceType)
      if (priceType === PriceType.ONE_TIME) {
        clearErrors('pricing.plans')
      } else {
        const paymentsAllowed = paymentMethodsFieldOptions
          .filter((paymentMethod) => paymentMethod.priceTypeAllowed.includes(priceType))
          .map((paymentMethod) => paymentMethod.name)
        if (paymentMethods) {
          // prevent clear value
          setValue(
            'checkout.paymentMethods',
            paymentMethods.filter((paymentMethod) => paymentsAllowed.includes(paymentMethod)),
          )
        }
        clearErrors('pricing.price')
        clearErrors('pricing.installments')
      }
    },
    [clearErrors, paymentMethods, setValue],
  )

  return (
    <>
      <OfferSection title={t('offer.pricingTab.pricing.title')}>
        <Tabs
          value={isRecurringPrice ? PriceType.RECURRING : PriceType.ONE_TIME}
          onValueChange={(value) => handleSelectPriceType(value as PriceType)}
          className="mb-6"
        >
          <TabsList className="grid grid-cols-2">
            {(!isPublished || (isPublished && !isRecurringPrice)) && (
              <TabsTrigger value={PriceType.ONE_TIME} disabled={isPublished} className="flex flex-row items-center">
                <Banknote className="w-4 h-4 mr-1" />
                {t('offer.pricingTab.pricing.subscriptionType.oneTime')}
              </TabsTrigger>
            )}
            {(!isPublished || (isPublished && isRecurringPrice)) && (
              <TabsTrigger value={PriceType.RECURRING} disabled={isPublished} className="flex flex-row items-center">
                <RefreshCwIcon className="w-4 h-4 mr-1" />
                {t('offer.pricingTab.pricing.subscriptionType.recurring')}
              </TabsTrigger>
            )}
          </TabsList>
        </Tabs>
        <div className="flex flex-col gap-4">
          {/* <CurrencySelect label={t('offer.pricingTab.pricing.currency.label')} /> */}
          <PriceConfiguration />
        </div>
      </OfferSection>
      <OfferSection title={t('offer.checkoutTab.paymentMethods.title')}>
        <PaymentMethodSelect />
      </OfferSection>
      <OfferSection title={t('offer.pricingTab.options.title')}>
        <Options />
      </OfferSection>
    </>
  )
}

export { PricingContent }
