import {
  Button,
  CopyClipboardButton,
  Separator,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@hub-la/shadcn'
import {
  Ban,
  BanknoteIcon,
  ChevronLeft,
  ChevronRight,
  CircleCheck,
  Folder,
  Globe,
  Info,
  ScrollText,
  TvMinimal,
  Undo2,
} from 'lucide-react'
import moment from 'moment'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { InvoiceEvent } from '../../../domain/dtos/invoice'
import { RefundRef } from '../../../domain/dtos/refund-ref'
import { InvoiceEventType } from '../../../domain/enums/invoice-event-type.enum'

type Props = {
  events: InvoiceEvent[][]
  refundRef?: RefundRef
}

export const InvoiceTimeline: React.FC<Props> = ({ events, refundRef }) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center w-full gap-2">
        <h2 className="text-lg font-semibold">Histórico de eventos</h2>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <Info className="h-4 w-4" />
            </TooltipTrigger>
            <TooltipContent>
              <p>Históricos cronológico dos eventos que ocorreram com essa fatura.</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <Separator />
      <div className="space-y-2">
        {Array.from(events)
          .reverse()
          .map((event, index) => {
            const showConnector = index !== events.length - 1
            return event.length === 1 ? (
              <Item
                key={index}
                icon={<EventIcon type={event[0].type} />}
                title={event[0].title}
                link={event[0].link}
                description={event[0].description}
                time={event[0].date}
                type={event[0].type}
                showConnector={showConnector}
                refundRef={refundRef}
              />
            ) : (
              <ItemList key={index} items={event} showConnector={showConnector} refundRef={refundRef} />
            )
          })}
      </div>
    </div>
  )
}

const Item: React.FC<{
  icon: React.ReactNode
  title: string
  description?: string
  time: string
  link?: string
  showConnector?: boolean
  controls?: JSX.Element
  refundRef?: RefundRef
  type?: InvoiceEventType
}> = ({ icon, title, description, type, time, link, showConnector = true, controls, refundRef }) => {
  const { t } = useTranslation()

  const isRefundType = type === InvoiceEventType.REFUNDED
  const refundPath = `/dashboard/refunds/${refundRef?.id}`

  return (
    <div>
      <div className="p-1">
        <div className="flex gap-4">
          <div className="flex flex-col items-center">
            <div className="rounded-full bg-secondary p-2">{icon}</div>
            {showConnector && <div className="w-px h-full bg-secondary my-3" />}
          </div>

          <div className="flex flex-col">
            <div className="flex gap-2 items-center">
              <Trans i18nKey={title} components={[<p className="text-sm" />]} />

              {link || (isRefundType && refundRef) ? (
                <CopyClipboardButton
                  copyContent={isRefundType ? `${window.location.origin}${refundPath}` : link || ''}
                  tooltipCopiedLabel={
                    isRefundType ? t('copyClipboard.refundIdCopied') : t('copyClipboard.eventIdCopied')
                  }
                  tooltipCopyLabel={isRefundType ? t('copyClipboard.copyRefundId') : t('copyClipboard.copyEventId')}
                />
              ) : null}
            </div>

            {description && (
              <p className="leading-tight text-sm text-muted-foreground whitespace-pre-line">{t(description)}</p>
            )}

            {refundRef?.description && isRefundType ? (
              <p className="text-sm mt-0.5 text-muted-foreground">{`Motivo: ${refundRef.description}`}</p>
            ) : null}
            <p className="text-sm mt-0.5 text-muted-foreground">{moment(time).format('D MMM. YYYY HH:mm:ss')}</p>

            {controls}
          </div>
        </div>
      </div>
    </div>
  )
}

const ItemList: React.FC<{
  items: InvoiceEvent[]
  showConnector: boolean
  refundRef?: RefundRef
}> = ({ items, showConnector, refundRef }) => {
  const [index, setIndex] = useState<number>(0)

  return (
    <Item
      icon={<EventIcon type={items[index].type} />}
      title={items[index].title}
      link={items[index].link}
      description={items[index].description}
      time={items[index].date}
      showConnector={showConnector}
      refundRef={refundRef}
      type={items[index].type}
      controls={
        <div className="flex items-center mt-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setIndex((prev) => (prev === 0 ? items.length - 1 : prev - 1))}
          >
            <ChevronLeft className="h-4 w-4" />
          </Button>
          <span className="text-xs text-muted-foreground">
            {index + 1} / {items.length}
          </span>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setIndex((prev) => (prev === items.length - 1 ? 0 : prev + 1))}
          >
            <ChevronRight className="h-4 w-4" />
          </Button>
        </div>
      }
    />
  )
}

const EventIcon: React.FC<{ type: InvoiceEventType | undefined }> = ({ type }) => {
  const commonStyles = 'h-4 w-4'
  switch (type) {
    case InvoiceEventType.CREATED:
    case InvoiceEventType.EXPIRED:
      return <ScrollText className={`${commonStyles}   text-muted-foreground`} />
    case InvoiceEventType.PROGRESS:
      return <CircleCheck className={`${commonStyles}   text-muted-foreground`} />
    case InvoiceEventType.FAILED:
      return <Ban className={`${commonStyles}  text-red-700`} />
    case InvoiceEventType.SUCCEDED:
      return <BanknoteIcon className={`${commonStyles} text-green-700`} />
    case InvoiceEventType.REFUNDED:
      return <Undo2 className={`${commonStyles} text-warning`} />
    case InvoiceEventType.SMART_INSTALLMENT_SUCCEEDED:
      return <TvMinimal className={`${commonStyles} text-green-700`} />
    case InvoiceEventType.SMART_INSTALLMENT_FAILED:
      return <TvMinimal className={`${commonStyles}  text-red-700`} />
    case InvoiceEventType.SMART_INSTALLMENT_STARTED:
      return <TvMinimal className={`${commonStyles}  text-muted-foreground`} />
    case InvoiceEventType.PAYMENT_PAGE_CREATED:
      return <Globe className={`${commonStyles}  text-muted-foreground`} />
    default:
      return <Folder className={`${commonStyles}   text-muted-foreground`} />
  }
}
