import { ImageUploader } from '@hub-la/fe-asset'
import { ImagePlus } from 'lucide-react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { CustomCollapsible } from '../../../../../components/custom-collapsible'

export const GuaranteeSealCollapsible = () => {
  const { t } = useTranslation()

  const { productId } = useParams<{ productId: string; offerId: string }>()

  return (
    <CustomCollapsible title={t(`offer.checkoutTab.customization.guaranteeSeal.title`)} Icon={ImagePlus}>
      <div className="grid gap-4">
        <div className="flex justify-between">
          <div>
            <h3 className="text-sm font-medium mb-1">
              {t('offer.checkoutTab.customization.guaranteeSeal.mobile.label')}
            </h3>
            <p className="text-sm text-muted-foreground">
              {t('offer.checkoutTab.customization.guaranteeSeal.mobile.description')}
            </p>
          </div>
          <Controller
            name="checkout.guaranteeSealMobileUrl"
            render={({ field }) => (
              <ImageUploader
                name={field.name}
                path={`/checkout/${productId}`}
                className="w-24 h-14"
                value={field.value}
                onChange={(file) => field.onChange(file?.URL)}
                obfuscateFileName
              />
            )}
          />
        </div>
        <div className="flex justify-between">
          <div>
            <h3 className="text-sm font-medium mb-1">
              {t('offer.checkoutTab.customization.guaranteeSeal.desktop.label')}
            </h3>
            <p className="text-sm text-muted-foreground">
              {t('offer.checkoutTab.customization.guaranteeSeal.desktop.description')}
            </p>
          </div>
          <Controller
            name="checkout.guaranteeSealDesktopUrl"
            render={({ field }) => (
              <ImageUploader
                name={field.name}
                path={`/checkout/${productId}`}
                className="w-24 h-14"
                value={field.value}
                onChange={(file) => field.onChange(file?.URL)}
                obfuscateFileName
              />
            )}
          />
        </div>
      </div>
    </CustomCollapsible>
  )
}
