import { ImageUploader } from '@hub-la/fe-asset'
import { useFeatureFlag } from '@hub-la/fe-core-feature-flag'
import { FooterBannerLayout, Tabs, TabsList, TabsTrigger } from '@hub-la/shadcn'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { BannerSize } from '../../../../../../domain/enums/banner-size.enum'
import { CustomCollapsible } from '../../../../../components/custom-collapsible'

export const FooterBannerCollapsible = () => {
  const { t } = useTranslation()

  const { productId, offerId } = useParams<{ productId: string; offerId: string }>()
  const isCheckoutV4EnabledToOffer = useFeatureFlag().isFlagEnabled('isCheckoutV4EnabledToOffer', {
    offerId,
  })

  return (
    <CustomCollapsible title={t(`offer.checkoutTab.customization.footerBanner.title`)} Icon={FooterBannerLayout}>
      <div className="grid gap-4">
        {isCheckoutV4EnabledToOffer && (
          <Controller
            name="checkout.footerBannerSize"
            render={({ field }) => <FooterSizeSelector value={field.value} onChange={field.onChange} />}
          />
        )}
        <div className="flex justify-between">
          <div>
            <h3 className="text-sm font-medium mb-1">
              {t('offer.checkoutTab.customization.footerBanner.mobile.label')}
            </h3>
            <p className="text-sm text-muted-foreground">
              {t('offer.checkoutTab.customization.footerBanner.mobile.description')}
            </p>
          </div>
          <Controller
            name="checkout.footerBannerMobileUrl"
            render={({ field }) => (
              <ImageUploader
                name={field.name}
                path={`/checkout/${productId}`}
                className="w-24 h-14"
                value={field.value}
                onChange={(file) => field.onChange(file?.URL)}
                obfuscateFileName
              />
            )}
          />
        </div>
        <div className="flex justify-between">
          <div>
            <h3 className="text-sm font-medium mb-1">
              {t('offer.checkoutTab.customization.footerBanner.desktop.label')}
            </h3>
            <p className="text-sm text-muted-foreground">
              {t('offer.checkoutTab.customization.footerBanner.desktop.description')}
            </p>
          </div>
          <Controller
            name="checkout.footerBannerDesktopUrl"
            render={({ field }) => (
              <ImageUploader
                name={field.name}
                path={`/checkout/${productId}`}
                className="w-24 h-14"
                value={field.value}
                onChange={(file) => field.onChange(file?.URL)}
                obfuscateFileName
              />
            )}
          />
        </div>
      </div>
    </CustomCollapsible>
  )
}

interface FooterSizeSelectorProps {
  onChange: (size: BannerSize) => void
  value: BannerSize
}

export const FooterSizeSelector = (props: FooterSizeSelectorProps) => {
  const { t } = useTranslation()

  return (
    <div>
      <h3 className="text-sm font-medium mb-1">{t('offer.checkoutTab.customization.footerBanner.size.label')}</h3>
      <Tabs value={props.value} onValueChange={(value) => props.onChange(value as BannerSize)} className="max-w-max">
        <TabsList className="flex">
          <TabsTrigger value={BannerSize.SMALL}>
            {t('offer.checkoutTab.customization.footerBanner.size.small')}
          </TabsTrigger>
          <TabsTrigger value={BannerSize.MEDIUM}>
            {t('offer.checkoutTab.customization.footerBanner.size.medium')}
          </TabsTrigger>
          <TabsTrigger value={BannerSize.LARGE}>
            {t('offer.checkoutTab.customization.footerBanner.size.large')}
          </TabsTrigger>
        </TabsList>
      </Tabs>
    </div>
  )
}
