import { useEffect } from 'react'
import { Checkbox, Input, Label } from '@hub-la/shadcn'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ColorPicker } from '../../../../../components/color-picker'
import { InstallmentsSelect } from '../../../../../components/installments-select'
import { PlansSelect } from '../../../../../components/plans-select'
import { SwitchToggleChildren } from '../../../../../components/switch-toggle-children'
import { OfferSchemaValidationType } from '../../../../../validations/offer-validation'
import { PriceType } from '../../../../../../domain/enums/price-type.enum'
import { TimePicker } from './time-picker'

export const CheckboxOptions = () => {
  const { t } = useTranslation()

  const { watch, control, getValues } = useFormContext<OfferSchemaValidationType>()
  const priceType = watch('pricing.priceType')

  return (
    <div className="space-y-4">
      <SwitchToggleChildren
        name="checkout.isDefaultOptions"
        label={t('offer.checkoutTab.options.defaultOptions.title')}
      >
        <div className="space-y-4">
          {priceType === PriceType.RECURRING && (
            <Controller
              name="checkout.defaultPlan"
              render={({ field, fieldState }) => (
                <PlansSelect
                  label={t('offer.checkoutTab.customization.preferences.planPreselected')}
                  error={Boolean(fieldState.error?.type)}
                  helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
                  {...field}
                  onValueChange={(value) => field.onChange(value)}
                />
              )}
            />
          )}

          <Controller
            name="checkout.defaultInstallment"
            render={({ field, fieldState }) => (
              <InstallmentsSelect
                label={t('offer.checkoutTab.customization.preferences.installmentPreselected')}
                error={Boolean(fieldState.error?.type)}
                helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
                {...field}
              />
            )}
          />
        </div>
      </SwitchToggleChildren>
      <Controller
        control={control}
        name="checkout.showCouponField"
        render={({ field: { value, onChange } }) => (
          <div className="flex items-center gap-2">
            <Checkbox id="showCupomField" checked={!value} onCheckedChange={(checked) => onChange(!checked)} />
            <Label htmlFor="showCupomField" className="text-sm">
              {t('offer.checkoutTab.options.showCouponField.title')}
            </Label>
          </div>
        )}
      />
      <SwitchToggleChildren
        name="checkout.billingAddress"
        label={t('offer.checkoutTab.options.billingAddress.title')}
      />
      <SwitchToggleChildren name="checkout.isTimer" label={t('offer.checkoutTab.options.timer.title')}>
        <Controller
          name="checkout.timerSeconds"
          control={control}
          render={({ field, fieldState }) => (
            <div className="space-y-2">
              <TimePicker name={'checkout.timerSeconds'} />

              {fieldState.error && <p className="text-sm text-red-500">{t(`fieldErrors.${fieldState.error.type}`)}</p>}
              <ColorPicker name="checkout.timerBgColor" />
            </div>
          )}
        />
      </SwitchToggleChildren>
    </div>
  )
}
