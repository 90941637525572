import {
  EditOfferAfterPurchaseSettingsDtoRedirectTypeEnum,
  EditOfferPricingSettingsDtoPriceTypeEnum,
  EditOfferResponseDto,
  EditOfferResponseDtoInstallmentCostsPayerEnum,
  OneTimePaymentPlanDto,
  PlanPriceDto,
  RecurringPlansDto,
  RecurringPlansDtoNameEnum,
  UpsellDto,
} from '@hub-la/sdk-bff-product'
import { OfferSchemaValidationType } from '../../presentation/validations/offer-validation'
import { USER_GROUPS_REDIRECT_URL } from '../constants/after-purchase-redirect'
import { BannerDisplayMode } from '../enums/banner-display-mode.enum'
import { BannerSize } from '../enums/banner-size.enum'
import { CheckoutLayoutType } from '../enums/checkout-layout-type.enum'
import { IntervalType } from '../enums/interval-type.enum'
import { LightDarkMode } from '../enums/light-dark-mode.enum'
import { LogoPosition } from '../enums/logo-position.enum'
import { PaymentMethod } from '../enums/payment-method.enum'
import { PriceFormat } from '../enums/price-format.enum'
import { PriceType } from '../enums/price-type.enum'
import { PurchaseExperienceEnum } from '../enums/purchase-experience.enum'
import { TimeString } from '../vos/time-string'

const parseToCents = (value: number) => {
  // Convert the amount to a string and ensure it has exactly two decimal places
  const strAmount = parseFloat(String(value)).toFixed(2)

  // Convert the string to a number of cents
  const cents = Math.round(parseFloat(strAmount) * 100)

  return cents
}

export const OfferDefaultValuesMapper = (
  productId: string,
  data?: Partial<EditOfferResponseDto> | undefined,
): OfferSchemaValidationType => {
  return {
    name: data?.name ?? '',
    productId,
    cohortId: (data?.cohortIds ?? [])[0] ?? '',
    isPublished: data?.isPublished ?? false,
    pricing: mapperPricing(data),
    checkout: mapperCheckout(data),
    afterPurchase: mapperAfterPurchase(data),
    isAvailableForAffiliates: data?.isAvailableForAffiliates ?? true,
    installmentCostsPayer: data?.installmentCostsPayer ?? EditOfferResponseDtoInstallmentCostsPayerEnum.BUYER,
  }
}

const mapperPricing = (data: Partial<EditOfferResponseDto> | undefined): OfferSchemaValidationType['pricing'] => {
  const offerPlans = data?.pricing?.plans as unknown as PlanPriceDto[]
  const plans = offerPlans
    ? offerPlans?.map((plan: any) => ({
        ...plan,
        price: plan?.oldPrice ? parseToCents(plan?.oldPrice ?? 10) : parseToCents(plan?.price ?? 10),
        oldPrice: plan?.oldPrice && plan?.price ? parseToCents(plan?.price ?? 10) : null,
      }))
    : [
        {
          installments: 1,
          interval: IntervalType.MONTHLY, // Provide a valid value for the periodicity property
          price: parseToCents(10),
          oldPrice: null,
        },
      ]

  const isMembershipFee =
    Boolean(
      data?.pricing?.membershipFee?.price &&
        data.pricing.priceType === EditOfferPricingSettingsDtoPriceTypeEnum.RECURRING,
    ) ?? false
  const oldPrice = (data?.pricing as any)?.oldPrice

  const automationSetting = data?.pricing?.automationSettings?.plans?.at(0)

  return {
    currency: 'BRL',
    accessDays:
      !data?.pricing?.accessDays || data?.pricing?.accessDays === 0 ? 'lifetime' : String(data?.pricing?.accessDays),
    priceType: data?.pricing?.priceType ?? PriceType.ONE_TIME,
    price: oldPrice ? parseToCents(oldPrice ?? 10) : parseToCents(data?.pricing?.price ?? 10),
    oldPrice: oldPrice && data?.pricing?.price ? parseToCents(data?.pricing?.price ?? 10) : null,
    installments: data?.pricing?.installments ?? 1,
    plans,
    isMembershipFee,
    isAutomationSettings: Boolean(automationSetting) ?? false,
    automationSettings: {
      disableAutoRenewalAfterCycles: automationSetting?.disableAutoRenewalAfterCycles ?? 2,
      creditsToAddOnFinalCycle: automationSetting?.creditsToAddOnFinalCycle ?? 1,
    },
    membershipFee: {
      installments: data?.pricing?.membershipFee?.installments ?? 1,
      price: parseToCents(data?.pricing?.membershipFee?.price ?? 10),
    },
    isSmartInstallment: Boolean(data?.pricing?.smartInstallment) ?? false,
    smartInstallment: {
      allowedDebit: data?.pricing?.smartInstallment?.allowedDebit ?? true,
      installments: data?.pricing?.smartInstallment?.installments ?? 12,
    },
  }
}

const mapperCheckout = (data: Partial<EditOfferResponseDto> | undefined): OfferSchemaValidationType['checkout'] => {
  return {
    creatorLogoUrl: data?.checkout?.logoUrl ?? '',
    bannerDesktopUrl: data?.checkout?.bannerDesktopUrl ?? '',
    bannerMobileUrl: data?.checkout?.bannerMobileUrl ?? '',
    bannerDisplayMode: data?.checkout?.bannerDisplayMode ?? BannerDisplayMode.FULL_SCREEN,
    bannerSize: data?.checkout?.bannerSize ?? BannerSize.LARGE,
    defaultPlan: (data?.checkout?.defaultPlan as any) ?? IntervalType.MONTHLY,
    lightDarkMode: data?.checkout?.lightDarkMode ?? LightDarkMode.LIGHT,
    logoPosition: data?.checkout?.logoPosition ?? LogoPosition.LEFT,
    bgColor: data?.checkout?.bgColor ?? '#F1F1EF',
    isDefaultOptions: !!data?.checkout?.defaultPlan || !!data?.checkout?.defaultInstallment,
    isCustomCta:
      !!data?.checkout?.purchaseButtonText ||
      !!data?.checkout?.purchaseButtonBgColor ||
      !!data?.checkout?.purchaseButtonTextColor,
    purchaseButtonText: data?.checkout?.purchaseButtonText ?? '',
    defaultInstallment: data?.checkout?.defaultInstallment ?? 1,
    formatCheckoutPrice: 'installments',
    paymentMethods: data?.checkout?.paymentMethods ?? [
      PaymentMethod.CREDIT_CARD,
      PaymentMethod.BANK_SLIP,
      PaymentMethod.PIX,
    ],
    purchaseExperience: data?.checkout?.purchaseExperience ?? PurchaseExperienceEnum.ONE_STEP,
    priceFormat: data?.checkout?.priceFormat ?? PriceFormat.PARTIAL,
    sideBannerMobileUrl: data?.checkout?.sideBannerMobileUrl ?? '',
    sideBannerDesktopUrl: data?.checkout?.sideBannerDesktopUrl ?? '',
    footerBannerMobileUrl: data?.checkout?.footerBannerMobileUrl ?? '',
    footerBannerDesktopUrl: data?.checkout?.footerBannerDesktopUrl ?? '',
    footerBannerSize: data?.checkout?.footerBannerSize ?? BannerSize.SMALL,
    guaranteeSealMobileUrl: data?.checkout?.guaranteeSealMobileUrl ?? '',
    guaranteeSealDesktopUrl: data?.checkout?.guaranteeSealDesktopUrl ?? '',
    purchaseButtonBgColor: data?.checkout?.purchaseButtonBgColor ?? '',
    purchaseButtonTextColor: data?.checkout?.purchaseButtonTextColor ?? '',
    checkoutlayoutType: data?.checkout?.layoutTemplate ?? CheckoutLayoutType.NO_BANNER,
    isTimer: Boolean(data?.checkout?.timerSeconds && data?.checkout?.timerSeconds > 0),
    timerBgColor: data?.checkout?.timerBgColor ?? '#f20000',
    timerSeconds: new TimeString('00:10:00').parseSeconds(data?.checkout?.timerSeconds) ?? '00:10:00',
    isYoutubeEmbed: Boolean(data?.checkout?.youtubeEmbedUrl),
    youtubeEmbedUrl: data?.checkout?.youtubeEmbedUrl ?? '',
    showCouponField: data?.checkout?.showCouponField ?? true,
    billingAddress: data?.checkout?.showBillingAddress ?? false,
    isCreatorSupport: Boolean(data?.checkout?.supportUrl),
    creatorSupportUrl: data?.checkout?.supportUrl ?? '',
    isTermsUrl: data?.checkout?.termsUrl ? true : false,
    termsUrl: data?.checkout?.termsUrl ?? '',
    isOrderBump: Boolean(data?.orderBump?.id),
    orderbump: makeCheckoutOrderbump(data),
  }
}

const makeCheckoutOrderbump = (
  data: Partial<EditOfferResponseDto> | undefined,
): OfferSchemaValidationType['checkout']['orderbump'] => {
  if (!data?.orderBump) {
    return null
  }

  return {
    id: data?.orderBump?.id ?? null,
    offerId: data?.orderBump?.extraOfferId ?? '',
    productId: data?.orderBump?.productExtraOfferId ?? '',
    product: {
      id: data?.orderBump?.productExtraOfferId ?? '',
      name: data?.orderBump?.extraOfferName ?? '',
      picture: data?.orderBump?.extraOfferPicture ?? '',
    },
    offer: {
      id: data?.orderBump?.extraOfferId ?? undefined,
      name: data?.orderBump?.extraOfferName ?? '',
      picture: data?.orderBump?.extraOfferPicture ?? '',
      price: (data?.orderBump?.plans as OneTimePaymentPlanDto)?.priceInCents ?? 0,
    },
    offers: (data as any)?.orderBump?.type?.extraOffers?.map((offer) => offer) ?? [],
    offersIds: (data as any)?.orderBump?.type?.extraOffersIds,
  }
}

const mapperAfterPurchase = (
  data: Partial<EditOfferResponseDto> | undefined,
): OfferSchemaValidationType['afterPurchase'] => {
  if (
    data?.afterPurchase?.redirectType === EditOfferAfterPurchaseSettingsDtoRedirectTypeEnum.USER_GROUPS &&
    data?.afterPurchase?.redirectUrl === USER_GROUPS_REDIRECT_URL
  ) {
    return {
      redirectType: 'user_groups',
      redirectUrl: USER_GROUPS_REDIRECT_URL,
      upsell: mapperAfterPurchaseUpsell(data),
    }
  }

  return {
    redirectType: data?.afterPurchase?.redirectType ?? 'user_groups',
    redirectUrl: data?.afterPurchase?.redirectUrl ?? '',
    upsell: mapperAfterPurchaseUpsell(data),
  }
}

const mapperAfterPurchaseUpsell = (
  data: Partial<EditOfferResponseDto> | undefined,
): OfferSchemaValidationType['afterPurchase']['upsell'] => {
  if (!data?.upsell) {
    return null
  }

  const upsellIsRecurring = data?.upsell?.plans?.name === RecurringPlansDtoNameEnum.RECURRING
  const upsellOfferPlans = upsellIsRecurring
    ? (data?.upsell?.plans as RecurringPlansDto)?.items.map((plan) => ({
        price: plan.priceInCents,
        interval: plan.interval.toLowerCase(),
      }))
    : null

  const oneTimePrice = !upsellIsRecurring ? (data?.upsell?.plans as OneTimePaymentPlanDto)?.priceInCents : null
  const oneTimeInstallments = !upsellIsRecurring
    ? (data?.upsell?.plans as OneTimePaymentPlanDto)?.maxInstallments
    : null

  return {
    id: data?.upsell?.id ?? null,
    offerId: data?.upsell?.extraOfferId ?? '',
    productId: data?.upsell?.productExtraOfferId ?? '',
    product: {
      id: data?.upsell?.productExtraOfferId ?? '',
      name: data?.upsell?.extraOfferName ?? '',
      picture: data?.upsell?.extraOfferPicture ?? '',
    },
    offer: {
      id: data?.upsell?.extraOfferId ?? '',
      name: data?.upsell?.extraOfferName ?? '',
      picture: data?.upsell?.extraOfferPicture ?? '',
      plans: upsellOfferPlans,
      price: oneTimePrice ?? parseToCents(10),
      installments: oneTimeInstallments,
    },
    redirectType: data?.afterPurchase?.upsell?.redirectType ?? '',
    youtubeEmbedUrl: (data?.upsell?.type as UpsellDto)?.mediaURL ? (data?.upsell?.type as UpsellDto)?.mediaURL : '',
    isYoutubeEmbed: Boolean((data?.upsell?.type as UpsellDto)?.mediaURL),
  }
}
