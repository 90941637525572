import { Skeleton } from "@hub-la/shadcn"
import { Search } from "lucide-react"
import { useGetInProgressSmartInstallmentCycles } from "../../../hooks/use-get-in-progress-smart-installment-cycles"
import { SmartInstallmentCycle } from "./smart-installment-cycle"
import { useTranslation } from "react-i18next"

export const SmartInstallmentsList = () => {
  const { data, isLoading } = useGetInProgressSmartInstallmentCycles()

  if (isLoading) {
    return <SmartInstallmentsCycleListLoading />
  }

  if (!data?.items.length) {
    return <SmartInstallmentsCycleEmpty />
  }

  return (
    <div className="flex flex-col gap-3">
      <h2 className="text-lg max-w-max">Compras parceladas em aberto</h2>
      {data?.items.map(invoice => <SmartInstallmentCycle key={invoice.id} invoice={invoice} />)}
    </div>
  )
}

const SmartInstallmentsCycleListLoading = () => {
  return (
    <div className="flex flex-col gap-3">
      <Skeleton className="w-[250px] h-[23px]" />
      {Array(3).fill(0).map((_, index) => <Skeleton className="h-[94px]" key={index} />)}
    </div>
  )
}

const SmartInstallmentsCycleEmpty = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center justify-center w-full h-full py-30">
      <div className="p-2 rounded-md border border-secondary bg-secondary/10">
        <Search />
      </div>
      <h2 className="mt-6 text-2xl font-semibold">{t('smartInstallmentCyclesEmpty')}</h2>
    </div>

  )
}
