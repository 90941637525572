import { EditOfferResponseDtoInstallmentCostsPayerEnum, OfferDto, ProductDto } from '@hub-la/sdk-bff-product'
import { array, boolean, InferType, mixed, number, object, string } from 'yup'
import { BannerDisplayMode } from '../../domain/enums/banner-display-mode.enum'
import { BannerSize } from '../../domain/enums/banner-size.enum'
import { CheckoutLayoutType } from '../../domain/enums/checkout-layout-type.enum'
import { IntervalType } from '../../domain/enums/interval-type.enum'
import { LightDarkMode } from '../../domain/enums/light-dark-mode.enum'
import { LogoPosition } from '../../domain/enums/logo-position.enum'
import { PaymentMethod } from '../../domain/enums/payment-method.enum'
import { PriceFormat } from '../../domain/enums/price-format.enum'
import { PurchaseExperienceEnum } from '../../domain/enums/purchase-experience.enum'
import { RedirectType } from '../../domain/enums/redirect-type.enum'
import { TimeString } from '../../domain/vos/time-string'
import { YoutubeUrl } from '../../domain/vos/youtube-url'

const transformNumber = (originalValue) => {
  if (!originalValue) return 0

  const value = String(originalValue).replace(/\D/g, '')

  return Number(value) / 100
}

const planPriceSchema = object({
  price: number()
    // Transform because the value is string in input
    .transform((_, originalValue) => transformNumber(originalValue))
    .min(10)
    .required(),
  interval: string().nullable(),
  installments: number().required(),
  // Transform because the value is string in input
  oldPrice: number()
    .nullable()
    .transform((value, originalValue) => {
      // Se o valor original for null, retorna null para ignorar as validações
      if (originalValue === null) return null
      // Apenas transforma se o valor original for um número
      return transformNumber(originalValue)
    })
    .test('isNumber', 'oldPrice must be a number', (value) => {
      return value === null || typeof value === 'number'
    })
    .test('isLessPrice', 'oldPrice must be less than price', function (value) {
      if (!value) return true

      return this.parent.price > value
    })
    .min(10),
})

export type PriceSchemaType = InferType<typeof planPriceSchema>

const pricingSchema = object({
  currency: string().required(),
  accessDays: mixed().oneOf(['1', '30', '90', '180', '270', '365', '730', '1095', 'lifetime']).nullable(),
  priceType: mixed().oneOf(['one_time', 'recurring']).required(),
  price: number().when('priceType', {
    is: 'one_time',
    then: number()
      .transform((_, originalValue) => transformNumber(originalValue))
      .min(10)
      .required(),
    otherwise: number().nullable(),
  }),
  oldPrice: number()
    .nullable()
    .transform((value, originalValue) => {
      // Se o valor original for null, retorna null para ignorar as validações
      if (originalValue === null) return null
      // Apenas transforma se o valor original for um número
      return transformNumber(originalValue)
    })
    .test('isNumber', 'oldPrice must be a number', (value) => {
      return value === null || typeof value === 'number'
    })
    .test('isLessPrice', 'oldPrice must be less than price', function (value) {
      if (!value) return true

      return this.parent.price > value
    })
    .min(10),
  installments: number().required(),
  plans: array<PriceSchemaType>().when('priceType', {
    is: 'recurring',
    then: array().of(planPriceSchema).required(),
    otherwise: array(),
  }),
  isMembershipFee: boolean().required(),
  isAutomationSettings: boolean().required(),
  automationSettings: object({
    disableAutoRenewalAfterCycles: number().min(2).max(12).required(),
    creditsToAddOnFinalCycle: number().min(1).required(),
  }).when('isSmartInstallment', {
    is: true,
    then: object().shape({
      disableAutoRenewalAfterCycles: number().min(2).max(12).required(),
      creditsToAddOnFinalCycle: number().min(1).required(),
    }),
    otherwise: object().nullable(),
  }),
  membershipFee: object({
    price: number().required(),
    installments: number().required(),
  }).when('isMembershipFee', {
    is: true,
    then: object({
      price: number()
        .transform((_, originalValue) => transformNumber(originalValue))
        .min(10)
        .required(),
      installments: number().required(),
    }),
    otherwise: object().nullable(),
  }),
  isSmartInstallment: boolean().required(),
  smartInstallment: object({
    installments: number().required(),
    allowedDebit: boolean().required(),
  }).when('isSmartInstallment', {
    is: true,
    then: object().shape({
      installments: number().required(),
      allowedDebit: boolean().required(),
    }),
    otherwise: object().nullable(),
  }),
})

const orderbumpSchema = object({
  id: string().nullable(),
  productId: string().nullable(),
  offer: mixed<OfferDto>().nullable(),
  offers: array(),
  // isTag: boolean().nullable(),
  // tag: string().nullable(),
  // isDescription: boolean().nullable(),
  //isYoutubeEmbed: boolean().nullable(),
  //youtubeEmbedUrl: string().nullable(),
})

const checkoutSchema = object({
  creatorLogoUrl: string().url().nullable(),
  bannerMobileUrl: string().url().nullable(),
  bannerDesktopUrl: string().url().nullable(),
  bannerDisplayMode: mixed().oneOf(Object.values(BannerDisplayMode)).nullable(),
  bannerSize: mixed().oneOf(Object.values(BannerSize)).nullable(),
  footerBannerMobileUrl: string().url().nullable(),
  footerBannerDesktopUrl: string().url().nullable(),
  footerBannerSize: mixed().oneOf(Object.values(BannerSize)).nullable(),
  sideBannerMobileUrl: string().url().nullable(),
  sideBannerDesktopUrl: string().url().nullable(),
  guaranteeSealMobileUrl: string().url().nullable(),
  guaranteeSealDesktopUrl: string().url().nullable(),
  defaultPlan: mixed<IntervalType>().oneOf(Object.values(IntervalType)).required(),
  defaultInstallment: number().positive(),
  formatCheckoutPrice: mixed().oneOf(['installments', 'full']).required(),
  lightDarkMode: mixed().oneOf(Object.values(LightDarkMode)),
  logoPosition: mixed().oneOf(Object.values(LogoPosition)),
  paymentMethods: array<PaymentMethod[]>()
    .of(mixed().oneOf(Object.values(PaymentMethod)))
    .required(),
  isTimer: boolean().required(),
  isDefaultOptions: boolean().required(),
  checkoutlayoutType: mixed().oneOf(Object.values(CheckoutLayoutType)),
  priceFormat: mixed().oneOf(Object.values(PriceFormat)),
  isCustomCta: boolean().required(),
  purchaseExperience: mixed().oneOf(Object.values(PurchaseExperienceEnum)),
  purchaseButtonText: string().nullable(),
  bgColor: string().matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/),
  purchaseButtonTextColor: string()
    .nullable()
    .when('isCustomCta', {
      is: true,
      then: string()
        .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
        .required(),

      otherwise: string().nullable(),
    }),
  purchaseButtonBgColor: string()
    .nullable()
    .when('isCustomCta', {
      is: true,
      then: string()
        .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
        .required(),

      otherwise: string().nullable(),
    }),
  timerBgColor: string()
    .nullable()
    .when('isTimer', {
      is: true,
      then: string()
        .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
        .required(),

      otherwise: string().nullable(),
    }),
  timerSeconds: string()
    .nullable()
    .when('isTimer', {
      is: true,
      then: string()
        .test('isGreaterFiveSeconds', 'Time must be at least 5 seconds', (value) => {
          if (!value) return false

          const inputSeconds = new TimeString(value).toSeconds()
          return inputSeconds >= 5
        })
        .required(),
      otherwise: string().nullable(),
    }),
  isYoutubeEmbed: boolean().required(),
  youtubeEmbedUrl: string().when('isYoutubeEmbed', {
    is: true,
    then: string()
      .url()
      .test('youtubeEmbedUrl', 'youtubeEmbedUrlError', (value) => {
        if (!value) {
          return false
        }

        const youtubeUrlIsValid = new YoutubeUrl(value).isValid()

        return youtubeUrlIsValid
      })
      .required(),
    otherwise: string().nullable(),
  }),
  showCouponField: boolean().required(),
  billingAddress: boolean().required(),
  isCreatorSupport: boolean().required(),
  creatorSupportUrl: string().when('isCreatorSupport', {
    is: true,
    then: string().url().required(),
    otherwise: string().nullable(),
  }),
  isTermsUrl: boolean().required(),
  termsUrl: string().when('isTermsUrl', {
    is: true,
    then: string().url().required(),
    otherwise: string().nullable(),
  }),
  isOrderBump: boolean().required(),
  /* TODO: orderbump to be implemented in the future */
  orderbump: mixed().nullable().when('isOrderBump', {
    is: 'upsell',
    then: orderbumpSchema.required(),
    otherwise: orderbumpSchema.nullable(),
  }),
})

const upsellRedirectSchema = object({
  id: string().nullable(),
  product: mixed<ProductDto>().nullable(),
  offer: mixed<OfferDto>().nullable(),
  //tag: string().nullable(),
  //description: string().nullable(),
  youtubeEmbedUrl: string().nullable(),
})

const afterPurchaseSchema = object({
  redirectType: mixed().oneOf(Object.values(RedirectType)).required(),
  redirectUrl: string()
    .nullable()
    .when('redirectType', {
      is: 'external_url',
      then: string()
        .url()
        .test('differentFromUpsellUrl', 'differentFromUpsellUrl', (value) => {
          const regexUpsell1Click = /pay\.hub\.la.*\/upsell/i

          return !regexUpsell1Click.test(value ?? '')
        })
        .required(),
      otherwise: string().nullable(),
    }),
  /** Upsell to be implemented in the future */
  upsell: mixed().nullable().when('redirectType', {
    is: 'upsell',
    then: upsellRedirectSchema.required(),
    otherwise: upsellRedirectSchema.nullable(),
  }),
})

export const offerSchemaValidation = object({
  name: string().required('validationErrors.name.required'),
  productId: string(),
  cohortId: string().required(),
  isPublished: boolean(),
  pricing: pricingSchema,
  checkout: checkoutSchema,
  afterPurchase: afterPurchaseSchema,
  isAvailableForAffiliates: boolean().required(),
  installmentCostsPayer: mixed().oneOf(Object.values(EditOfferResponseDtoInstallmentCostsPayerEnum)),
}).required()

export type OfferSchemaValidationType = InferType<typeof offerSchemaValidation>
