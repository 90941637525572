import { useGetUser } from '@hub-la/fe-get-user'
import { Button } from '@hub-la/shadcn'
import { ExternalLink } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IntegrationProvider } from '../../../domain/dtos/integration-provider'
import { Envs } from '../../../envs'
import { DisableIntegrationModal } from '../disable-integration-modal'

type RDStationCardActionsProps = {
  provider: IntegrationProvider
  integrationId?: string
  loading: boolean
  refetch: () => void
  setTab: (name: string) => void
}

export const RDStationCardActions = (props: RDStationCardActionsProps) => {
  const { provider, integrationId, loading, refetch, setTab } = props
  const { t } = useTranslation()
  const { data: currentUser, isLoading: loadingUser } = useGetUser()
  const [showDisableIntegration, setShowDisableIntegration] = useState(false)

  const connectRDStation = () => {
    const url = new URL(`https://api.rd.services/auth/dialog`)
    url.searchParams.set('client_id', Envs.RDSTATION_CLIENT_ID ?? '')
    url.searchParams.set('redirect_uri', Envs.RDSTATION_REDIRECT_URI ?? '')
    url.searchParams.set('state', `${provider}:${currentUser?.id}`)

    window.location.href = url.toString()
  }

  return (
    <div className="w-full flex flex-col items-center justify-between gap-2">
      {!integrationId ? (
        <Button className="w-full" loading={loading} onClick={connectRDStation}>
          {t(`buttons.activeIntegration`)}
        </Button>
      ) : (
        <>
          <Button className="w-full" variant="outline" loading={loading} onClick={() => setTab('settings')}>
            {t(`buttons.settings`)}
          </Button>
          <Button
            className="w-full"
            variant="outline"
            loading={loading}
            onClick={() => setShowDisableIntegration(true)}
          >
            {t(`buttons.disable`)}
          </Button>
        </>
      )}

      {integrationId && (
        <DisableIntegrationModal
          integrationId={integrationId}
          provider={provider}
          isOpen={showDisableIntegration}
          onClose={() => setShowDisableIntegration(false)}
          onConfirm={() => {
            setShowDisableIntegration(false)
            refetch()
          }}
        />
      )}

      <a
        className="flex items-center gap-2 font-normal text-sm text-muted-foreground hover:underline mt-2"
        href={t(`integrations.${provider}.about.link`)}
        target="_blank"
        rel="noreferrer"
      >
        {t('aboutTab.seeMore')}
        <ExternalLink size={16} />
      </a>
    </div>
  )
}
