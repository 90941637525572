import { AuthenticationProvider, AuthRedirect, AuthRedirectProvider, AutoAuthPage } from '@hub-la/fe-auth'
import { container, ContainerProvider } from '@hub-la/fe-container'
import { VideosUploader, VideosUploaderProvider } from '@hub-la/fe-post'
import {
  PermissionResource,
  RoleplayModeAlert,
  RoleplayPermissionProvider,
  RoleplayProvider,
} from '@hub-la/fe-roleplay'
import { ThemeProvider, Toaster } from '@hub-la/shadcn'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createBrowserHistory } from 'history'
import { Route, Router, Switch } from 'react-router-dom'
import { DashboardContainer } from './components/dashboard-container'
import { PrivateRoute } from './components/private-route'
import { UserContainer } from './components/user-container'
import { useAppcues } from './hooks/use-appcues'
import { AffiliationProgramInit } from './pages/affiliation-program-init'
import { CoProductionInit } from './pages/co-production-init'
import { CohortInit } from './pages/cohort-init'
import { CreatePostInit } from './pages/create-post-init'
import { DashboardAffiliatesInit } from './pages/dashboard-affiliates-init'
import { DashboardCouponDetailInit } from './pages/dashboard-coupon-detail-init'
import { DashboardCouponsInit } from './pages/dashboard-coupons-init'
import { DashboardEventsInit } from './pages/dashboard-events-init'
import { DashboardFinancialInit } from './pages/dashboard-financial-init'
import { DashboardHomeInit } from './pages/dashboard-home-init'
import { DashboardIntegrationsInit } from './pages/dashboard-integrations-init'
import { DashboardInvoiceDetailInit } from './pages/dashboard-invoice-detail-init'
import { DashboardProductsInit } from './pages/dashboard-products-init'
import { DashboardRefundDetailInit } from './pages/dashboard-refund-detail-init'
import { DashboardRefundsInit } from './pages/dashboard-refunds-init'
import { DashboardSalesInit } from './pages/dashboard-sales-init'
import { DashboardSettingsInit } from './pages/dashboard-settings-init'
import { DashboardSmartInstallmentDetailInit } from './pages/dashboard-smart-installments-init'
import { DashboardSubscriptionDetailInit } from './pages/dashboard-subscriptions-detail-init'
import { DashboardSubscriptionsInit } from './pages/dashboard-subscriptions-init'
import { EditOrderInit } from './pages/edit-order-init'
import { EditProductInit } from './pages/edit-product-init'
import { EventLiveInit } from './pages/event-live-init'
import { ExportInit } from './pages/export-init'
import { FreeMembersInit } from './pages/free-members-init'
import { InviteInit } from './pages/invite-init'
import { LetsStartCardInit } from './pages/lets-start-init'
import { OfferInit } from './pages/offer-init'
import { PostDetailInit } from './pages/post-detail-init'
import { PrivacyPolicyInit } from './pages/privacy-policy'
import { RecoverPasswordInit } from './pages/recover-password-init'
import { RecoverPasswordTokenInit } from './pages/recover-password-token-init'
import { RedirectCollaboratorsInit } from './pages/redirect-collaborators-init'
import { RoleplayInit } from './pages/roleplay-init'
import { SelectUserNavigationInit } from './pages/select-user-navigation'
import { SignInEmailInit } from './pages/signin-email-init'
import { SignInInit } from './pages/signin-init'
import { SignInSmsInit } from './pages/signin-sms-init'
import { SignInWhatsappInit } from './pages/signin-whatsapp-init'
import { SignUpInit } from './pages/signup-init'
import { TelegramVerificationDoneInit } from './pages/telegram-verification-done-init'
import { TelegramVerificationInitInit } from './pages/telegram-verification-init-init'
import { TelegramVerificationIntroInit } from './pages/telegram-verification-intro-init'
import { TelegramVerificationQRCodeInit } from './pages/telegram-verification-qrcode-init'
import { TelegramVerificationStartInit } from './pages/telegram-verification-start-init'
import { TermsOfUseInit } from './pages/terms-of-use-init'
import { TicketInit } from './pages/ticket-init'
import { UserEventsDetailInit } from './pages/user-events-detail-init'
import { UserGroupsInit } from './pages/user-groups'
import { UserInvoicesInit } from './pages/user-invoices'
import { UserInvoicesDetailsInit } from './pages/user-invoices-details'
import { UserInvoicesDownloadInit } from './pages/user-invoices-download'
import { UserPendenciesInit } from './pages/user-pendencies'
import { UserRefundsInit } from './pages/user-refunds'
import { UserSmartInstallmentsInit } from './pages/user-smart-installments-init'
import { UserSubscriptionsInit } from './pages/user-subscriptions'
import { UserSubscriptionsDetailInit } from './pages/user-subscriptions-detail'
import { WelcomeExperienceInit } from './pages/welcome-experience-init'
import { WhitelistInit } from './pages/whitelist-init'
import { AutoAuthProvider } from './providers/auto-auth'

const client = new QueryClient()

const history = createBrowserHistory()

export default function App() {
  useAppcues()

  return (
    <ThemeProvider>
      <ContainerProvider container={container}>
        <QueryClientProvider client={client}>
          <VideosUploaderProvider>
            <AutoAuthProvider>
              <RoleplayPermissionProvider>
                <RoleplayProvider>
                  <RoleplayModeAlert />
                </RoleplayProvider>
                <Router history={history}>
                  <Switch>
                    <AuthenticationProvider>
                      <Route exact={true} path="/autoauth/:id" component={AutoAuthPage} />
                      <Route exact={true} path="/collaborators" component={RedirectCollaboratorsInit} />
                      <Route
                        path={[
                          '/signup',
                          '/signin',
                          '/signin/sms',
                          '/signin/whatsapp',
                          '/signin/email',
                          '/recover-password',
                          '/recover-password/:token',
                          '/auth',
                        ]}
                        exact
                      >
                        <Switch>
                          <AuthRedirectProvider>
                            <Route path="/auth" component={AuthRedirect} />
                            <Route exact={true} path="/signup" component={SignUpInit} />
                            <Route exact={true} path="/signin" component={SignInInit} />
                            <Route exact={true} path="/signin/sms" component={SignInSmsInit} />
                            <Route exact={true} path="/signin/whatsapp" component={SignInWhatsappInit} />
                            <Route exact={true} path="/signin/email" component={SignInEmailInit} />
                            <Route exact={true} path="/recover-password" component={RecoverPasswordInit} />
                            <Route exact={true} path="/recover-password/:token" component={RecoverPasswordTokenInit} />
                          </AuthRedirectProvider>
                        </Switch>
                      </Route>

                      {/* Routes for unauthenticated users */}
                      <Route exact={true} path="/welcome-experience" component={WelcomeExperienceInit} />
                      <Route exact={true} path="/lets-start" component={LetsStartCardInit} />
                      <Route exact={true} path="/post/:postId" component={PostDetailInit} />

                      <Route exact={true} path="/terms_of_use" component={TermsOfUseInit} />
                      <Route exact={true} path="/privacy_policy" component={PrivacyPolicyInit} />

                      {/* Protected routes for authenticated users */}
                      <Route
                        path={[
                          '/user_groups',
                          '/refund',
                          '/user_events/:productId',
                          '/user_invoices',
                          '/user_invoices/:invoiceId',
                          '/user_invoices/:invoiceId/pdf',
                          '/user_pendencies',
                          '/group_affiliate/:groupId',
                          '/user_subscriptions',
                          '/user_subscriptions/:subscriptionId',
                          '/user_smart_installments',
                        ]}
                        exact
                      >
                        <Switch>
                          <UserContainer>
                            <PrivateRoute exact={true} path="/refund" component={UserRefundsInit} />
                            <PrivateRoute exact={true} path="/user_groups" component={UserGroupsInit} />
                            <PrivateRoute
                              exact={true}
                              path="/user_smart_installments"
                              component={UserSmartInstallmentsInit}
                            />
                            <PrivateRoute
                              exact={true}
                              path="/user_events/:productId"
                              component={UserEventsDetailInit}
                            />
                            <PrivateRoute exact={true} path="/user_invoices" component={UserInvoicesInit} />
                            <PrivateRoute
                              exact={true}
                              path="/user_invoices/:invoiceId"
                              component={UserInvoicesDetailsInit}
                            />
                            <PrivateRoute
                              exact={true}
                              path="/user_invoices/:invoiceId/pdf"
                              component={UserInvoicesDownloadInit}
                            />
                            <PrivateRoute exact={true} path="/user_pendencies" component={UserPendenciesInit} />
                            <PrivateRoute exact={true} path="/user_subscriptions" component={UserSubscriptionsInit} />
                            <PrivateRoute
                              exact={true}
                              path="/user_subscriptions/:subscriptionId"
                              component={UserSubscriptionsDetailInit}
                            />
                            <PrivateRoute
                              exact={true}
                              path="/group_affiliate/:groupId"
                              component={AffiliationProgramInit}
                            />
                          </UserContainer>
                        </Switch>
                      </Route>

                      {/* Protected routes for authenticated users */}
                      <PrivateRoute exact={true} path="/" component={SelectUserNavigationInit} />
                      <PrivateRoute exact={true} path="/groups_order" component={EditOrderInit} />
                      <PrivateRoute exact={true} path="/edit/:productId/offers/:offerId" component={OfferInit} />
                      <PrivateRoute exact={true} path="/edit/:productId/cohorts/:cohortId" component={CohortInit} />
                      <PrivateRoute exact={true} path="/edit/:productId/tickets/:ticketId" component={TicketInit} />
                      <PrivateRoute
                        exact={true}
                        path="/verification-telegram/init"
                        component={TelegramVerificationInitInit}
                      />
                      <PrivateRoute exact={true} path="/live/:postId" component={EventLiveInit} />
                      <PrivateRoute
                        exact={true}
                        path="/verification-telegram/intro"
                        component={TelegramVerificationIntroInit}
                      />
                      <PrivateRoute
                        exact={true}
                        path="/verification-telegram/start"
                        component={TelegramVerificationStartInit}
                      />
                      <PrivateRoute
                        exact={true}
                        path="/verification-telegram/qrcode"
                        component={TelegramVerificationQRCodeInit}
                      />
                      <PrivateRoute
                        exact={true}
                        path="/verification-telegram/done"
                        component={TelegramVerificationDoneInit}
                      />
                      <PrivateRoute
                        exact={true}
                        path="/hub/create_post/:productId/:sectionId"
                        component={CreatePostInit}
                      />

                      {/* Routes without padding */}
                      <Route path={['/edit/:productId', '/edit/:productId/:menu']} exact>
                        <Switch>
                          <DashboardContainer disablePadding>
                            <PrivateRoute exact={true} path="/edit/:productId" component={EditProductInit} />
                            <PrivateRoute exact={true} path="/edit/:productId/:menu" component={EditProductInit} />
                          </DashboardContainer>
                        </Switch>
                      </Route>

                      {/* Routes without sidebar */}
                      <Route path={['/invites/:inviteId/accept']} exact>
                        <Switch>
                          <DashboardContainer hideSidebar>
                            <Route exact={true} path="/invites/:inviteId/accept" component={InviteInit} />
                          </DashboardContainer>
                        </Switch>
                      </Route>

                      {/* Routes without sidebar and header */}
                      <Route path={['/roleplay', '/whitelist/*', '/export/*', '/free_members/*']} exact>
                        <Switch>
                          <DashboardContainer hideSidebar disablePadding disableAppHeader>
                            <PrivateRoute exact={true} path="/roleplay" component={RoleplayInit} />
                            <PrivateRoute
                              exact={true}
                              path="/free_members/:groupResourceId/:productId/:groupId"
                              component={FreeMembersInit}
                            />
                            <PrivateRoute exact={true} path="/whitelist/:groupId" component={WhitelistInit} />
                            <PrivateRoute exact={true} path="/export/:exportId" component={ExportInit} />
                          </DashboardContainer>
                        </Switch>
                      </Route>

                      {/* Routes with sidebar */}
                      <Route exact path={['/dashboard', '/dashboard/*', '/co-production/*']}>
                        <Switch>
                          <DashboardContainer>
                            {/* Home doesn't have a roleplay permission, because redirects to the first page with permission */}
                            <PrivateRoute exact={true} path="/dashboard" component={DashboardHomeInit} />
                            <PrivateRoute
                              exact={true}
                              path="/dashboard/sales"
                              component={DashboardSalesInit}
                              roleplayPermissions={[PermissionResource.SALES]}
                            />
                            <PrivateRoute
                              exact={true}
                              path="/dashboard/sales/:invoiceId"
                              component={DashboardInvoiceDetailInit}
                              roleplayPermissions={[PermissionResource.SALES]}
                            />
                            <PrivateRoute
                              exact={true}
                              path="/dashboard/sales/smart-installments/:smartInstallmentId"
                              component={DashboardSmartInstallmentDetailInit}
                              roleplayPermissions={[PermissionResource.SALES]}
                            />
                            <PrivateRoute
                              exact={true}
                              path="/dashboard/refunds"
                              component={DashboardRefundsInit}
                              roleplayPermissions={[PermissionResource.REFUNDS]}
                            />
                            <PrivateRoute
                              exact={true}
                              path="/dashboard/refunds/:refundId"
                              component={DashboardRefundDetailInit}
                              roleplayPermissions={[PermissionResource.REFUNDS]}
                            />
                            <PrivateRoute
                              exact={true}
                              path="/dashboard/affiliates"
                              component={DashboardAffiliatesInit}
                              roleplayPermissions={[PermissionResource.AFFILIATES]}
                            />
                            <PrivateRoute
                              exact={true}
                              path="/dashboard/coupons"
                              component={DashboardCouponsInit}
                              roleplayPermissions={[PermissionResource.COUPONS]}
                            />

                            <PrivateRoute
                              exact={true}
                              path="/dashboard/coupons/:couponId"
                              component={DashboardCouponDetailInit}
                              roleplayPermissions={[PermissionResource.COUPONS]}
                            />
                            <PrivateRoute
                              exact={true}
                              path="/dashboard/financial"
                              component={DashboardFinancialInit}
                              roleplayPermissions={[PermissionResource.WALLET]}
                            />
                            <PrivateRoute
                              exact={true}
                              path="/dashboard/subscriptions"
                              component={DashboardSubscriptionsInit}
                              roleplayPermissions={[PermissionResource.SUBSCRIPTIONS]}
                            />
                            <PrivateRoute
                              exact={true}
                              path="/dashboard/subscriptions/:subscriptionId"
                              component={DashboardSubscriptionDetailInit}
                              roleplayPermissions={[PermissionResource.SUBSCRIPTIONS]}
                            />
                            <PrivateRoute
                              exact={true}
                              path="/dashboard/products"
                              component={DashboardProductsInit}
                              roleplayPermissions={[PermissionResource.PRODUCTS]}
                            />
                            <PrivateRoute
                              exact={true}
                              path="/dashboard/events"
                              component={DashboardEventsInit}
                              roleplayPermissions={[PermissionResource.PRODUCTS]}
                            />
                            <PrivateRoute
                              exact={true}
                              path="/dashboard/integrations"
                              component={DashboardIntegrationsInit}
                              roleplayPermissions={[PermissionResource.INTEGRATIONS]}
                            />
                            <PrivateRoute exact={true} path="/dashboard/settings" component={DashboardSettingsInit} />

                            <PrivateRoute
                              exact={true}
                              path="/co-production/:groupId/:receiverId?"
                              component={CoProductionInit}
                              roleplayPermissions={[PermissionResource.PRODUCTS_MANAGE_CO_PRODUCTION]}
                            />
                          </DashboardContainer>
                        </Switch>
                      </Route>
                    </AuthenticationProvider>
                  </Switch>
                </Router>
                <Toaster />
                <VideosUploader />
              </RoleplayPermissionProvider>
            </AutoAuthProvider>
          </VideosUploaderProvider>
        </QueryClientProvider>
      </ContainerProvider>
    </ThemeProvider>
  )
}
