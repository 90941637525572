import { Badge, Button } from "@hub-la/shadcn"
import { AlertTriangle, ChevronRight, CircleAlert } from "lucide-react"
import { useTranslation } from "react-i18next"
import { formatCurrency } from "../../../../domain/vos/format-currency"
import { useGetSmartInstallmentCyclesSummarie } from "../../../hooks/use-get-smart-installment-cycles-summarie"

export const OverdueSmartInstallmentActionButton = () => {
  const { t } = useTranslation()

  const { data } = useGetSmartInstallmentCyclesSummarie()

  if (!data?.amountTotalOverdueCents) {
    return <></>
  }

  return (
    // TODO: Implement redirect when installments list page is done
    <Button variant="secondary" className="w-full px-4 py-3 gap-2">
      <div className="w-full flex items-center gap-2">
        <AlertTriangle className="text-red-400" />
        <span>{t('smartInstallment.overdueInstallments')}</span>
      </div>
      <Badge variant="destructive" className="rounded-full text-red-400">{formatCurrency(data?.amountTotalOverdueCents ?? 0)}</Badge>
    </Button>
  )
}
