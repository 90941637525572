import { Badge, Button, Separator, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@hub-la/shadcn'
import { RefreshCcw } from 'lucide-react'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { CardDetail } from '../../../domain/dtos/card-detail'
import { Invoice } from '../../../domain/dtos/invoice'
import { CardBrand } from '../../../domain/enums/card-brand'
import { InvoiceType } from '../../../domain/enums/invoice-type'
import { PaymentMethod } from '../../../domain/enums/payment-method'
import { PlanType } from '../../../domain/enums/plan-type'
import { SubscriptionStatus } from '../../../domain/enums/subscription-status'
import { SubscriptionType } from '../../../domain/enums/subscription-type'
import amex from '../../assets/brands/amex.png'
import elo from '../../assets/brands/elo.png'
import mastercard from '../../assets/brands/mastercard.png'
import visa from '../../assets/brands/visa.png'
import { SubscriptionStatusStats } from './subscription-status-stats'

type Props = {
  invoice: Invoice
  subscriptionRefs?: {
    credits: number
    id: string
    plan: PlanType
    status: SubscriptionStatus
    type: SubscriptionType
    orderBumpTagNumber?: number
  }[]
}

const creditCardIcons = {
  [CardBrand.CARD_BRAND_VISA]: <img className="w-[30px] object-contain" src={visa} aria-label={visa} />,
  [CardBrand.CARD_BRAND_MASTERCARD]: (
    <img className="w-[30px] object-contain" src={mastercard} aria-label={mastercard} />
  ),
  [CardBrand.CARD_BRAND_AMEX]: <img className="w-[30px] object-contain" src={amex} aria-label={amex} />,
  [CardBrand.CARD_BRAND_ELO]: <img className="w-[30px] object-contain" src={elo} aria-label={elo} />,
}

export const Details: React.FC<Props> = ({ invoice, subscriptionRefs }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const mainSubscription = subscriptionRefs?.find((ref) => ref.type === SubscriptionType.TYPE_RECURRING)
  const allSubscriptions = subscriptionRefs?.filter((ref) => ref.type === SubscriptionType.TYPE_RECURRING)

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation() // Prevent event bubbling if parent is clickable
    if (mainSubscription?.id) {
      history.push(`/dashboard/subscriptions/${mainSubscription.id}`)
    }
  }

  const getText = (paymentMethod?: PaymentMethod, cardDetails?: CardDetail) => {
    if (paymentMethod === PaymentMethod.PIX) return 'Pix'
    if (paymentMethod === PaymentMethod.BOLETO) return 'Boleto'
    if (paymentMethod === PaymentMethod.CREDIT_CARD) {
      return cardDetails ? `•••• ${cardDetails?.last4}` : 'Cartão de crédito'
    }
    return ''
  }

  return (
    <div className="w-full flex flex-col gap-4 md:gap-6 md:flex-row">
      <div className="space-y-1">
        <h4 className="text-sm text-muted-foreground font-medium leading-none">Data de criação</h4>
        <p className="text-sm">{moment(invoice.createdAt).format('DD MMM. YYYY HH:mm')}</p>
      </div>
      <div>
        <Separator className="w-px hidden md:block h-full" orientation="vertical" />
      </div>
      <div className="space-y-1">
        <h4 className="text-sm text-muted-foreground font-medium leading-none">Cliente</h4>
        <p className="text-sm">{invoice.payer.identity?.fullName}</p>
      </div>
      <div>
        <Separator className="w-px hidden md:block h-full" orientation="vertical" />
      </div>
      <div className="space-y-1">
        <h4 className="text-sm text-muted-foreground font-medium leading-none">Método de pagamento</h4>
        <div className="flex flex-row items-center gap-1">
          {invoice.cardDetails?.brand && creditCardIcons[invoice.cardDetails.brand]}
          <p className="text-sm">{getText(invoice.paymentMethod, invoice.cardDetails)}</p>
        </div>
      </div>
      {mainSubscription && (
        <>
          <div>
            <Separator className="w-px hidden md:block h-full" orientation="vertical" />
          </div>
          <div className="space-y-1">
            <h4 className="text-sm text-muted-foreground font-medium leading-none">Assinatura</h4>
            <div className="flex flex-row items-end">
              <div className="flex items-center space-x-1">
                <Button size="sm" variant="link" className="p-0 h-5" onClick={handleClick}>
                  <p className="text-sm mr-2">{t(`planType.${mainSubscription?.plan}`)}</p>
                  <SubscriptionStatusStats status={mainSubscription.status} />
                </Button>
                {mainSubscription.orderBumpTagNumber && (
                  <Badge variant="secondary" className="whitespace-pre">
                    Order bump {mainSubscription.orderBumpTagNumber}
                  </Badge>
                )}
              </div>
              {allSubscriptions?.length && allSubscriptions?.length > 1 && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Badge variant="secondary" className="ml-2">
                        + {allSubscriptions?.length - 1}
                      </Badge>
                    </TooltipTrigger>
                    <TooltipContent>
                      {allSubscriptions.slice(1)?.map((ref) => (
                        <div
                          key={ref.id}
                          className="flex items-center justify-between space-x-4 p-2 cursor-pointer hover:bg-muted rounded-md"
                          onClick={() => history.push(`/dashboard/subscriptions/${ref.id}`)}
                        >
                          <div className="flex items-center space-x-1">
                            <p className="text-sm mr-2">{t(`planType.${ref.plan}`)}</p>
                            <SubscriptionStatusStats status={ref.status} />
                          </div>
                          {ref.orderBumpTagNumber && (
                            <Badge variant="secondary" className="whitespace-pre">
                              Order bump {ref.orderBumpTagNumber}
                            </Badge>
                          )}
                        </div>
                      ))}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
          </div>
        </>
      )}
      {invoice.type === InvoiceType.RENEWAL && (
        <>
          <div>
            <Separator className="w-px hidden md:block h-full" orientation="vertical" />
          </div>
          <div className="space-y-1">
            <h4 className="text-sm text-muted-foreground font-medium leading-none">Tipo</h4>
            <div className="flex flex-row items-center gap-1">
              <RefreshCcw className="w-3 h-3 text-blue-500" />
              <p className="text-sm">Renovação</p>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
